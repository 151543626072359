import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

const blink = keyframes`
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
`;

const Cursor = styled.span`
  display: inline-block;
  width: 2px;
  height: 1em;
  background-color: #fff;
  margin-left: 5px;
  animation: ${blink} 0.7s infinite;
`;

const TextWrapper = styled.span`
  display: inline-block;
  min-width: 300px; // Increased from 240px
  font-size: 3.5rem; // Increased font size
  font-weight: bold; // Made text bold
  color: #11e5a3; // Changed color to make it stand out (adjust as needed)
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); // Added text shadow for emphasis
`;

const AnimatedWord = styled.span`
  position: relative;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -5px;
    width: 100%;
    height: 3px;
    background-color: #104dff; // Underline color (adjust as needed)
    transform: scaleX(0);
    transition: transform 0.3s ease;
  }

  &:hover::after {
    transform: scaleX(1);
  }
`;

const words = [
  'World-Class Matching Engine',
  'Customizable Interface',
  'Ultra-Low Fees',
  'Regulated by SC',
  'Bespoke Services',
];

function AnimatedText() {
  const [index, setIndex] = useState(0);
  const [subIndex, setSubIndex] = useState(0);
  const [reverse, setReverse] = useState(false);

  useEffect(() => {
    if (subIndex === words[index].length + 1 && !reverse) {
      setReverse(true);
      return;
    }

    if (subIndex === 0 && reverse) {
      setReverse(false);
      setIndex(prev => (prev + 1) % words.length);
      return;
    }

    const timeout = setTimeout(() => {
      setSubIndex(prev => prev + (reverse ? -1 : 1));
    }, Math.max(reverse ? 50 : subIndex === words[index].length ? 500 : 100, parseInt(Math.random() * 200)));

    return () => clearTimeout(timeout);
  }, [subIndex, index, reverse]);

  return (
    <TextWrapper>
      <AnimatedWord>{`${words[index].substring(0, subIndex)}`}</AnimatedWord>
      <Cursor />
    </TextWrapper>
  );
}

export default AnimatedText;
