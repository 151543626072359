import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';

import {
  updateTradingPair,
  updateOrderBook,
  updateOrderBookTotal,
  updateTradeHistory,
  initializeTradeHistory,
  initializeOrderBook,
  UPDATE_ORDER_BOOK_ASKS,
  UPDATE_ORDER_BOOK_BIDS,
  clearExchange,
} from 'redux/actions/exchange';

import {
  initializeOpenOrders,
  updateOpenOrders,
  getTradeHistory,
} from 'redux/actions/orders';
import { socket } from 'realtime';

const TradeData = ({
  authorization,
  initializeOrderBook,
  initializeTradeHistory,
  updateTradeHistory,
  initializeOpenOrders,
  tradingPair,
  updateTradingPair,
  updateOrderBookTotal,
  isAuthenticated,
  clearExchange,
  isOrderBook = false,
}) => {
  const [socketTradingPair, setSocketTradingPair] = useState();

  const unSubscribeAll = () => {
    initializeTradeHistory([]);
    if (socketTradingPair) {
      socket.unsubscribe(
        ['OB', 'RT', 'OT'],
        `${socketTradingPair.baseCurrency}_${socketTradingPair.quoteCurrency}`,
      );
      if (!isOrderBook) {
        // socket.unsubscribe('CH');
      }
    }
  };
  const invokeNewTradingPair = useCallback(
    tradingPair => {
      unSubscribeAll();
      let wsModulus = ['OB', 'RT', 'OT'];

      updateTradingPair(tradingPair);

      socket.subscribe(
        wsModulus,
        `${tradingPair.baseCurrency}_${tradingPair.quoteCurrency}`,
      );

      if (isAuthenticated) {
        // store.getState().orders.openOrders = [];
        initializeOpenOrders([]);
        socket.subscribe(
          'PO',
          `${tradingPair.baseCurrency}_${tradingPair.quoteCurrency}`,
        );
      }

      window.addEventListener('online', () => {
        setTimeout(() => {
          socket.subscribe(
            wsModulus,
            `${tradingPair.baseCurrency}_${tradingPair.quoteCurrency}`,
          );

          if (isAuthenticated) {
            // store.getState().orders.openOrders = [];
            initializeOpenOrders([]);
            socket.subscribe(
              'PO',
              `${tradingPair.baseCurrency}_${tradingPair.quoteCurrency}`,
            );
          }
        }, 5000);
      });
    },
    [updateTradingPair, isAuthenticated, authorization],
  );

  const updateSocketConnection = useCallback(
    (status = 'on') => {
      const handleUpdateTradeHistory = data => {
        if (data && data[0]) {
          updateTradeHistory({ Data: data[0] });
        }
      };

      const handleOrderBook = data => {
        const { bids, asks } = data;
        initializeOrderBook(asks, UPDATE_ORDER_BOOK_ASKS);
        initializeOrderBook(bids, UPDATE_ORDER_BOOK_BIDS);
      };
      socket[status]('OB', handleOrderBook);
      const handleAllMatched = data => {
        initializeTradeHistory(data);
      };
      socket[status]('RT', handleAllMatched);

      const handleOrderBookTotal = data => {
        updateOrderBookTotal(data);
      };
      socket[status]('OT', handleOrderBookTotal);

      const handleAllPendingOrders = data => {
        initializeOpenOrders(data);
      };
      if (!isOrderBook) {
        socket[status]('PO', handleAllPendingOrders);
      }
    },
    [
      initializeOrderBook,
      initializeTradeHistory,
      updateTradeHistory,
      initializeOpenOrders,
      updateOrderBookTotal,
      isOrderBook,
    ],
  );

  useEffect(() => {
    updateSocketConnection();

    return () => {
      updateSocketConnection('off');
    };
  }, [updateSocketConnection]);

  useEffect(() => {
    if (!_.isEqual(tradingPair, socketTradingPair)) {
      invokeNewTradingPair(tradingPair);
      setSocketTradingPair(tradingPair);
    }
  }, [
    tradingPair,
    invokeNewTradingPair,
    setSocketTradingPair,
    socketTradingPair,
  ]);

  useEffect(() => {
    return () => {
      unSubscribeAll();
    };
  }, [socketTradingPair]);

  useEffect(() => {
    if (socketTradingPair) {
      if (isAuthenticated) {
        // store.getState().orders.openOrders = [];
        initializeOpenOrders([]);
        socket.subscribe(
          'PO',
          `${socketTradingPair.baseCurrency}_${socketTradingPair.quoteCurrency}`,
        );
      }
    }

    window.addEventListener('online', () => {
      setTimeout(() => {
        if (socketTradingPair) {
          if (isAuthenticated) {
            // store.getState().orders.openOrders = [];
            initializeOpenOrders([]);
            socket.subscribe(
              'PO',
              `${socketTradingPair.baseCurrency}_${socketTradingPair.quoteCurrency}`,
            );
          }
        }
      }, 5000);
    });

    return () => {
      if (socketTradingPair) {
        if (isAuthenticated) {
          socket.unsubscribe(
            'PO',
            `${socketTradingPair.baseCurrency}_${socketTradingPair.quoteCurrency}`,
          );
        }
      }
    };
  }, [isAuthenticated, socketTradingPair]);

  useEffect(() => {
    return () => {
      clearExchange();
    };
  }, [clearExchange]);

  return <React.Fragment />;
};

TradeData.propTypes = {
  tradingPair: PropTypes.object.isRequired,
};

const mapStateToProps = ({ auth, markets }) => ({
  authorization: auth.authorization,
  isAuthenticated: auth.isAuthenticated,
  markets,
});

export default connect(mapStateToProps, {
  updateTradingPair,
  updateOrderBook,
  updateOrderBookTotal,
  updateTradeHistory,
  initializeTradeHistory,
  initializeOrderBook,
  initializeOpenOrders,
  updateOpenOrders,
  clearExchange,
  getTradeHistory,
})(TradeData);
