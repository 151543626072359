import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Star } from 'grommet-icons';
import styled from 'styled-components';

import styles from './CoinTracker.module.scss';
import { CryptoIcon } from 'components/CryptoIcon';
import { Loading } from 'components/Loading';
import { CoinTrackerTable } from 'containers/Tables';
import { Box, Button, Text } from 'components/Wrapped';
import { MessageModal } from 'containers/Modals';
import { nestedTranslate } from 'utils';

const StyledStar = styled(Star)`
  height: 20px;
  width: 20px;
  margin-left: 5px;
`;

class CoinTrackerContainer extends Component {
  state = {
    currentMarket: null,
    isLoading: true,
    selectedGroupName: '',
    currentPair: '',
    modalOpen: false,
  };

  static propTypes = {
    vhLoader: PropTypes.bool,
  };

  currentMarket = '';

  closeModal() {
    this.setState({ modalOpen: false });
  }

  componentDidUpdate(prevProps) {
    const { notabene, transactions } = this.props;
    console.log('transactions -> ', transactions);
    if (
      prevProps.pendingDepositsCount !== this.props.pendingDepositsCount &&
      transactions?.enableTravelRules
    ) {
      this.setState({ modalOpen: true });
    }
  }
  componentDidMount() {
    const {
      marketsByGroup,
      currentBaseCurrency,
      currentQuoteCurrency,
    } = this.props;
    let values = [];
    Object.entries(marketsByGroup).forEach(([key, value]) => {
      values = value;
      values.forEach(element => {
        if (element.includes('_')) {
          if (element === `${currentBaseCurrency}_${currentQuoteCurrency}`) {
            this.currentMarket = key;
          }
        } else if (element === currentQuoteCurrency) {
          this.currentMarket = key;
        }
      });
    });

    if (this.props.exchangeMarkets) {
      if (this.currentMarket !== null && this.currentMarket !== '') {
        this.setState({
          currentMarket: this.currentMarket,
        });
      } else {
        this.setState({
          currentMarket: this.props.exchangeMarkets[1],
        });
      }
    }
    const cm = localStorage.getItem('currentMarket');
    if (!window.location.href.includes('/trade/') && cm) {
      this.setState({
        currentMarket: cm,
      });
      setTimeout(() => {
        localStorage.setItem('currentMarket', '');
      });
    }
  }

  handleActiveMarket(currentMarket) {
    if (window.location.href.includes('/trade/')) {
      localStorage.setItem('currentMarket', currentMarket);
    } else {
      localStorage.setItem('currentMarket', '');
    }
    this.setState({ selectedGroupName: currentMarket });
    this.setState({
      currentMarket,
    });
  }

  renderIcon({ value, original: { baseCurrency } }) {
    return (
      <div className={styles.coinWrap}>
        <CryptoIcon currency={baseCurrency} className={styles.icon} />
        {value}
      </div>
    );
  }

  renderFavorite() {
    const isActive = this.state.currentMarket === 'favorites';
    const color = isActive ? 'primary' : false;

    return (
      <Box direction="row" justify="center" pad="none">
        {this.props.t('tables.coinTracker.favorites')}
        <StyledStar size="16" color={color} />
      </Box>
    );
  }

  renderMarketBox = (market, key, isActive) => {
    return (
      <div
        key={key}
        onClick={() => this.handleActiveMarket(market)}
        className="pointer"
      >
        <Box
          pad={{ horizontal: 'small', vertical: 'xsmall' }}
          border={{
            size: '1px',
            color: isActive ? 'primary' : 'border',
            side: 'all',
          }}
          align="center"
          flex={false}
        >
          <Text level={5} color={isActive ? 'primary' : null}>
            {market !== 'favorites' ? market : this.renderFavorite()}
          </Text>
        </Box>
      </div>
    );
  };

  render() {
    const { t: translate, pendingDepositsCount } = this.props;
    const t = nestedTranslate(translate, 'messages');
    const { currentMarket, modalOpen } = this.state;
    const {
      exchangeMarkets,
      vhLoader,
      background = 'background-3',
    } = this.props;

    return (
      <Fragment>
        {currentMarket ? (
          <Box background={background} gap="small">
            <Box
              direction="row"
              gap="small"
              align="center"
              wrap={false}
              overflow="auto"
              pad="none"
              flex={false}
            >
              {exchangeMarkets.map((market, i) =>
                this.renderMarketBox(
                  market,
                  i,
                  (currentMarket ? currentMarket : exchangeMarkets[0]) ===
                    market,
                ),
              )}
            </Box>
            <CoinTrackerTable currentMarket={currentMarket} />
          </Box>
        ) : (
          <Loading fullVh={vhLoader} />
        )}
        <MessageModal
          show={modalOpen}
          onClose={() => {
            this.closeModal();
          }}
          confirm={() => {}}
          title={`You have ${pendingDepositsCount} ${t('pending deposit(s)')}`}
        >
          <Box direction="column">
            <Box>
              According to the regulatoy rules we must ask you to provide
              information about deposit originator before funds will become
              available for you. Push the button below and add the required
              information.
            </Box>
            <Box>
              <Button
                color="primary"
                onClick={() => this.props.history.push(`/wallet/deposits`)}
              >
                {t('buttons.OpenDeposit')}
              </Button>
            </Box>
          </Box>
        </MessageModal>
      </Fragment>
    );
  }
}

const mapStateToProps = ({
  exchangeSettings: {
    settings: { exchangeMarkets, marketsByGroup, notabene, transactions },
  },
  user: { pendingDepositsCount },
}) => ({
  exchangeMarkets,
  marketsByGroup,
  pendingDepositsCount,
  notabene,
  transactions,
});

const CoinTracker = withRouter(
  withTranslation()(connect(mapStateToProps)(CoinTrackerContainer)),
);

export default CoinTracker;
