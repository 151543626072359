import React, { useCallback, useEffect, useState } from 'react';
import _, { find } from 'lodash';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Formik, Form, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { Columns } from 'react-bulma-components';
import { Button, Box, Tag, Paragraph, CheckBox } from 'components/Wrapped';
import { FormField, NumberInput, TextField } from 'components/Form';
import {
  getWithdrawalHistory,
  withdrawalRequest,
} from 'redux/actions/portfolio';
import {
  formatNumberToPlaces,
  // hasAddressValidator,
  // validateAddress,
  numberParser,
  convertCurrency,
} from 'utils';
import { ConfirmModal } from 'containers/Modals';
import { nestedTranslate } from 'utils/strings';
import { withTranslation } from 'react-i18next';
import { WithdrawalHeadingValue } from './Withdrawal';
import RequestWithdrawalCode from './RequestWithdrawalCode';
import { exchangeApi } from 'api';
import {
  AddressBookSelect,
  useAddressWhitelistStatus,
} from 'containers/AddressBook';
import { authenticatedInstance } from 'api';
import { triggerToast, triggerModalOpen } from 'redux/actions/ui';
import styles from './Wallet.module.scss';
import { Heading, Text } from 'grommet';
import { ReactSelect } from 'components/Form/SelectField';
import countries from 'i18n-iso-countries';

const FIXED = 'Fixed';
const PERCENTAGE = 'Percentage';
const BOTH = 'Fixed + Percentage';

const AddressBookSelectField = withTranslation()(
  ({ currency, addressWhitelistEnabled, t }) => {
    const { setFieldValue } = useFormikContext();

    const handleChange = ({ value: { Address, DT_Memo } }) => {
      setFieldValue('address', Address);
      setFieldValue('addressTag', DT_Memo);
    };

    return (
      <Box pad="none" gap="small" margin={{ bottom: 'small' }}>
        <Box pad="small" background="background-2">
          <Paragraph>
            {addressWhitelistEnabled
              ? t('wallet.addressBook.selectAddressWhitelist')
              : t('wallet.addressBook.selectAddress')}
          </Paragraph>
        </Box>
        <AddressBookSelect currency={currency} onChange={handleChange} />
      </Box>
    );
  },
);

// const WithdrawalFormOtp = ({ onSuccess, t, currency }) => {
//   const { values } = useFormikContext();
//   const { address, amount } = values;

//   let enabled = false;
//   if (amount > 0 && address !== '') {
//     enabled = true;
//   }
//   return (
//     <RequestWithdrawalCode
//       messageText={t('emailOtpMessage')}
//       successText={t('emailOtpSuccessMessage')}
//       buttonText={t('emailOtpButton')}
//       requestMethod={exchangeApi.requestWithdrawalCode}
//       requestData={{ currency, address, amount }}
//       background="background-3"
//       disabled={!enabled}
//       handleSuccess={onSuccess}
//     />
//   );
// };

const AmountFields = withTranslation()(
  ({
    currency,
    t: translate,
    balance,
    decimalPrecision,
    currencyCode,
    fiatConverted,
    currencyInfo,
  }) => {
    const t = nestedTranslate(translate, 'forms.walletWithdrawal');

    const withdrawalServiceChargeFixed = formatNumberToPlaces(
      _.get(currencyInfo, 'withdrawalServiceChargeFixed'),
    );
    const withdrawalServiceChargePercentage = formatNumberToPlaces(
      _.get(currencyInfo, 'withdrawalServiceChargePercentage'),
    );

    const {
      setFieldValue,
      values: { paymentAmount },
    } = useFormikContext();

    const handleValueUpdate = useCallback(
      (name, value) => {
        if (name === 'amount') {
          const amount = convertCurrency(value, {
            from: currency,
            to: currencyCode,
          });
          setFieldValue('fiatAmount', amount);
        }

        if (name === 'fiatAmount') {
          const amount = convertCurrency(value, {
            from: currencyCode,
            to: currency,
          });
          setFieldValue('amount', amount);
          setFieldValue('travelRuleRequest.amount', amount);
        }
      },
      [setFieldValue],
    );

    const handleChange = useCallback(
      e => {
        const {
          target: { name, value },
        } = e;
        const newValue = numberParser.parse(value);
        handleValueUpdate(name, newValue);
      },
      [handleValueUpdate],
    );

    return (
      <div>
        <Columns className={styles.columnsRow}>
          <Columns.Column className={styles.columnsCol}>
            <FormField name="amount" label={t('amount.placeholder')}>
              <NumberInput
                type="text"
                addonEnd={{
                  content: `${formatNumberToPlaces(balance)} ${currency}`,
                  onClick: () => {
                    let fixedFee = parseFloat(withdrawalServiceChargeFixed);
                    let percFee = parseFloat(withdrawalServiceChargePercentage);

                    let amount = balance;
                    if (fixedFee > 0) {
                      amount = amount - fixedFee;
                    }
                    if (percFee > 0) {
                      amount = formatNumberToPlaces(
                        (amount * 100) / (100 + percFee),
                        8,
                      );
                    }
                    setFieldValue('amount', amount);
                  },
                  background: 'primary',
                }}
                inputOnChange={handleChange}
                precision={decimalPrecision}
              />
            </FormField>
          </Columns.Column>
          <Columns.Column className={styles.columnsCol}>
            <FormField
              name="fiatAmount"
              label={`${currencyCode} ${t('amount.placeholder')}`}
            >
              <NumberInput
                type="text"
                addonEnd={{
                  content: `${formatNumberToPlaces(
                    fiatConverted,
                    2,
                  )} ${currencyCode}`,
                  // onClick: () => setFieldValue('fiatAmount', fiatConverted),
                  background: 'primary',
                }}
                inputOnChange={handleChange}
                precision={2}
              />
            </FormField>
          </Columns.Column>
        </Columns>
      </div>
    );
  },
);

const FiatConvert = ({ currencyCode, balance, currency }) => {
  const value = convertCurrency(balance, {
    from: currency,
    to: currencyCode,
  });

  return `${formatNumberToPlaces(value, 2)} ${currencyCode}`;
};

const Withdrawals = ({
  currency,
  currencyInfo,
  withdrawalRequest,
  t: translate,
  balanceInfo: { balance, balanceInTrade, totalBalance },
  calculateServiceCharge,
  decimalPrecision,
  gAuthEnabled,
  currencyCode,
  isMobileVerified,
  email,
  mobileNumber,
  notabene,
  transactions,
}) => {
  const t = nestedTranslate(translate, 'forms.walletWithdrawal');
  const ts = nestedTranslate(translate, 'wallet.withdrawals');

  const { data: addressWhitelistData } = useAddressWhitelistStatus();
  const [isNotabene, setIsNotabene] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [emailOtpSuccess, setEmailOtpSuccess] = useState(false);
  const [addressWhitelistEnabled, setAddressWhitelistEnabled] = useState(false);
  const closeModal = () => setModalOpen(false);
  const [emailToken, setEmailToken] = useState('');
  const [smsToken, setSMSToken] = useState('');
  const [fiatConverted, setFiatConverted] = useState('');
  const [isVerified, setIsVerified] = useState(false);

  const [modalNotabene, setModalNotabene] = useState(false);
  const [vaspsDids, setVaspsDids] = useState([]);
  const [resetForm, setResetForm] = useState(true);
  const closeModalNotabene = () => setModalNotabene(false);

  const hasAddressTag = !_.isEmpty(currencyInfo.addressSeparator);
  const isUsingGAuth = gAuthEnabled;
  let addressTagType = 'addressTag';

  if (hasAddressTag && currencyInfo.addressSeparator.includes('memoid')) {
    addressTagType = 'memoId';
  }

  useEffect(() => {
    if (addressWhitelistData?.status === 'Success') {
      setAddressWhitelistEnabled(addressWhitelistData.data);
    }
  }, [addressWhitelistData]);

  useEffect(() => {
    convertFiat();
  });

  const confirmWithdrawal = (values, resetForm, setFieldValue) => {
    delete values.fiatAmount;
    // delete values.gauth_code;
    withdrawalRequest(
      { ...values },
      {
        cb: data => {
          if (data) {
            resetForm();
          } else {
            setFieldValue('email_otp', '');
            setFieldValue('sms_otp', '');
            setFieldValue('IVeReadAndAcceptTheTermsOfService', false);
          }
          setEmailOtpSuccess(false);
        },
        hasAddressTag,
      },
    );
    closeModal();
  };

  const testAddress = address => {
    if (address && address.length < 10) {
      return false;
      // console.log('hi', currencyInfo)
      // const hasValidation = hasAddressValidator(currencyInfo.shortName);

      // if (!hasValidation) {
      //   return true;
      // }
      // return validateAddress(address.trim(), currencyInfo.shortName);
    }
    return true;
  };

  const convertFiat = () => {
    const value = convertCurrency(balance, {
      from: currency,
      to: currencyCode,
    });

    setFiatConverted(value);
    return value;
  };

  useEffect(() => {
    countries.registerLocale(require('i18n-iso-countries/langs/en.json'));
    if (transactions?.enableTravelRules) {
      setIsNotabene(true);
      getAllVasps();
    }
  }, []);

  const renderCountryOptions = () => {
    const countryList = countries.getNames('en');
    return Object.entries(countryList).map(([countryCode, countryName]) => ({
      value: countryCode,
      label: countryName,
    }));
  };

  const getAllVasps = async () => {
    let {
      data: {
        data: { vasps },
      },
    } = await authenticatedInstance({
      url: '/api/get-all-vasps',
      method: 'GET',
    });
    vasps = vasps?.map(vasp => ({ label: vasp.name, value: vasp.did }));
    setVaspsDids(vasps);
  };
  const validateBeneficiary = async (values, setFieldValue) => {
    const pData = {
      currency: currency,
      address: values.address,
      amount: values.amount,
      beneficiaryVASPdid: values.travelRuleRequest.beneficiaryVASPdid,
      beneficiaryVASPname: values.travelRuleRequest.beneficiaryVASPname,
      itsMyAccount: values.travelRuleRequest.ItsMyAccount,
      iConfirmBeneficiaryAddressIsCorrect:
        values.travelRuleRequest.IConfirmBeneficiaryAddressIsCorrect,
      beneficiaryPrimaryName: values.travelRuleRequest.BeneficiaryPrimaryName,
      beneficiarySecondaryName:
        values.travelRuleRequest.BeneficiarySecondaryName,
      BeneficiaryGeographicAddress:
        values.travelRuleRequest.BeneficiaryGeographicAddress,
      BeneficiaryCountryOfResidence:
        values.travelRuleRequest.BeneficiaryCountryOfResidence,
    };
    let notToCheck = ['itsMyAccount', 'beneficiaryVASPname'];
    if (!notabene?.enabled) {
      notToCheck = [...notToCheck, 'beneficiaryVASPdid'];
    }
    const vData = Object.keys(pData).filter(
      key => !pData[key] && notToCheck.indexOf(key) === -1,
    );
    if (vData.length) {
      vData.map(key => {
        console.log(`messages.${key}IsRequired`);
        triggerToast(`${key}IsRequired`, 'error');
      });
      return;
    }
    delete pData.BeneficiaryGeographicAddress;
    delete pData.BeneficiaryCountryOfResidence;
    const { data } = await authenticatedInstance({
      url: '/api/TravelRule/ValidateTransaction',
      method: 'POST',
      data: pData,
    });
    if (data.status === 'Success') {
      if (data?.data?.isValid) {
        triggerToast('successfullyValidated', 'success');
        closeModalNotabene();
      } else if (data?.data?.err?.message) {
        const message = JSON.parse(data?.data?.err?.message);
        message?.map(error => triggerToast(error.message, 'error'));
      } else if (data?.data?.errors) {
        data?.data?.errors?.map(error => triggerToast(error, 'error'));
      }
      setFieldValue('isVerified', data?.data?.isValid);
      setFieldValue(
        'travelRuleRequest.skip_travelrule',
        data?.data?.skip_travelrule,
      );
    } else {
      triggerToast(data?.data, 'error');
    }
  };

  const validationSchema = () => {
    const shape = {
      amount: Yup.number()
        .typeError(translate('forms.validations.required'))
        .required()
        .min(currencyInfo.minWithdrawalLimit),
      address: Yup.string()
        .required()
        .test(
          'addressValidation',
          translate('forms.validations.invalidAddress', {
            currencyName: currencyInfo.fullName,
          }),
          testAddress,
        ),
      email_otp: Yup.string()
        .required()
        .min(6),
      gauth_code: isUsingGAuth
        ? Yup.string()
            .required()
            .min(6)
        : Yup.string(),
      sms_otp: isMobileVerified
        ? Yup.string()
            .required()
            .min(6)
        : Yup.string(),
      IVeReadAndAcceptTheTermsOfService: Yup.boolean().required(),
    };
    return Yup.lazy(values => {
      let amountInUsd = 0;
      if (values.amount) {
        amountInUsd = convertCurrency(values.amount, {
          from: currency,
          to: 'USD',
        });
      }
      if (isNotabene && amountInUsd > 999) {
        shape.isVerified = Yup.boolean().required();
        shape.travelRuleRequest = Yup.object().shape({
          BeneficiaryPrimaryName: Yup.string().required(),
          BeneficiarySecondaryName: Yup.string().required(),
        });
      }
      return Yup.object().shape(shape);
    });
  };

  const renderWithdrawalServiceCharge = currency => {
    const withdrawalServiceChargeType = _.get(
      currencyInfo,
      'withdrawalServiceChargeType',
    );
    const withdrawalServiceChargeFixed = formatNumberToPlaces(
      _.get(currencyInfo, 'withdrawalServiceChargeFixed'),
    );
    const withdrawalServiceChargePercentage = formatNumberToPlaces(
      _.get(currencyInfo, 'withdrawalServiceChargePercentage'),
    );

    switch (withdrawalServiceChargeType) {
      case FIXED:
        return {
          fixedCharge: `${withdrawalServiceChargeFixed} ${currency}`,
          PercentageCharge: null,
        };
      case PERCENTAGE:
        return {
          PercentageCharge: `${withdrawalServiceChargePercentage}%`,
          fixedCharge: null,
        };
      case BOTH:
        return {
          fixedCharge: `${withdrawalServiceChargeFixed} ${currency}`,
          PercentageCharge: `${withdrawalServiceChargePercentage}%`,
        };
      default:
        return '';
    }
  };
  // const { values } = useFormikContext();
  // if(values !== undefined){
  //   const {address, amount } = values;

  //   const enabled = currency && address;
  // }
  // const handleOtpSuccess = data => {
  //   setEmailOtpSuccess(true);
  //   setTempToken(data.token);
  // };

  const requestEmailCode = async (values, currency, otpType) => {
    const { amount, address } = values;

    if (amount > 0 && address !== '') {
      try {
        const { data } = await authenticatedInstance({
          url: '/api/RequestWithdraw_EmailOTP',
          method: 'POST',
          data: {
            amount: amount,
            currency: currency,
            address: address,
            otp_type: otpType,
          },
        });

        if (data.status === 'Success') {
          if (otpType === 'sms') {
            setSMSToken(data.data.token);
          } else if (otpType === 'email') {
            setEmailToken(data.data.token);
          }

          triggerToast(data.message, 'success');
        } else {
          triggerToast(data.message, 'error');
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  const { fixedCharge, PercentageCharge } = renderWithdrawalServiceCharge(
    currency,
  );

  useEffect(() => {
    setResetForm(false);
    const timeoutId = setTimeout(() => {
      setResetForm(true);
    });
    return () => {
      clearTimeout(timeoutId);
    };
  }, [currency]);

  return (
    <React.Fragment>
      <Box pad={{ vertical: 'small' }}>
        <WithdrawalHeadingValue
          heading={ts('totalBalance')}
          value={`${formatNumberToPlaces(totalBalance)} ${currency}`}
        />
        <WithdrawalHeadingValue
          heading={ts('inOrders')}
          value={`${formatNumberToPlaces(balanceInTrade)} ${currency}`}
        />
        <WithdrawalHeadingValue
          heading={ts('availableBalance')}
          value={`${formatNumberToPlaces(balance)} ${currency}`}
        />
      </Box>
      <Box background="background-4">
        <Paragraph color="status-warning">{ts('warning')}</Paragraph>
        <Tag.Group>
          {!_.isEqual(fixedCharge, null) && _.isEqual(PercentageCharge, null) && (
            <Tag>
              {ts('fee')} {`${fixedCharge}`}
            </Tag>
          )}
          {_.isEqual(fixedCharge, null) && !_.isEqual(PercentageCharge, null) && (
            <Tag>
              {ts('fee')} {`${PercentageCharge}`}
            </Tag>
          )}
          {!_.isEqual(fixedCharge, null) && !_.isEqual(PercentageCharge, null) && (
            <Tag>
              {ts('fee')} {`${fixedCharge} + ${PercentageCharge}`}
            </Tag>
          )}
        </Tag.Group>
        {resetForm && !_.isEmpty(currencyInfo) ? (
          <Formik
            validationSchema={validationSchema()}
            initialValues={{
              fiatAmount: '',
              amount: '',
              address: '',
              addressTag: '',
              gauth_code: '',
              email_otp: '',
              sms_otp: '',
              // notabeans fields
              travelRuleRequest: {
                beneficiaryVASPdid: '',
                beneficiaryVASPname: '',

                amount: 0,
                address: '',
                ItsMyAccount: false,
                IConfirmBeneficiaryAddressIsCorrect: false,
                BeneficiaryPrimaryName: '',
                BeneficiarySecondaryName: '',

                BeneficiaryGeographicAddress: '',
                BeneficiaryCountryOfResidence: '',
                BeneficiaryCountryOfResidenceName: '',
              },
              IVeReadAndAcceptTheTermsOfService: false,
            }}
            onSubmit={(values, actions) => setModalOpen(true)}
          >
            {({ values, setFieldValue, resetForm, isValid, errors }) => {
              const canOpenForm =
                errors?.address ||
                errors?.travelRuleRequest?.BeneficiaryPrimaryName ||
                errors?.travelRuleRequest?.BeneficiarySecondaryName;
              const {
                serviceCharge,
                balanceAfter,
                willReceive,
              } = calculateServiceCharge(balance, values.amount);
              let amountInUsd = 0;
              if (values.amount) {
                amountInUsd = convertCurrency(values.amount, {
                  from: currency,
                  to: 'USD',
                });
              }
              return (
                <Form>
                  <AddressBookSelectField
                    currency={currency}
                    addressWhitelistEnabled={addressWhitelistEnabled}
                  />
                  {isNotabene && (
                    <Columns className={styles.columnsRow}>
                      <Columns.Column className={styles.columnsCol}>
                        <FormField
                          name="travelRuleRequest.BeneficiaryPrimaryName"
                          label={t('travelRuleRequest.BeneficiaryPrimaryName')}
                        >
                          <TextField
                            type="text"
                            placeholder={t(
                              'travelRuleRequest.BeneficiaryPrimaryName',
                            )}
                            disabled={addressWhitelistEnabled}
                          />
                        </FormField>
                      </Columns.Column>
                      <Columns.Column className={styles.columnsCol}>
                        <FormField
                          name="travelRuleRequest.BeneficiarySecondaryName"
                          label={t(
                            'travelRuleRequest.BeneficiarySecondaryName',
                          )}
                        >
                          <TextField
                            type="text"
                            placeholder={t(
                              'travelRuleRequest.BeneficiarySecondaryName',
                            )}
                            disabled={addressWhitelistEnabled}
                          />
                        </FormField>
                      </Columns.Column>
                    </Columns>
                  )}

                  <FormField
                    name="address"
                    label={t('address.placeholder', { currency })}
                  >
                    <TextField
                      type="text"
                      placeholder={t('address.placeholder', { currency })}
                      disabled={addressWhitelistEnabled}
                    />
                  </FormField>

                  <FormField
                    name="addressTag"
                    label={t(`${addressTagType}.placeholder`, { currency })}
                    hidden={!hasAddressTag}
                  >
                    <TextField
                      type="text"
                      placeholder={t(`${addressTagType}.placeholder`, {
                        currency,
                      })}
                      disabled={addressWhitelistEnabled}
                    />
                  </FormField>

                  <AmountFields
                    fiatConverted={fiatConverted}
                    balance={balance}
                    currency={currency}
                    decimalPrecision={decimalPrecision}
                    currencyCode={currencyCode}
                    currencyInfo={currencyInfo}
                  />

                  <Box pad="none" className={styles.verificationFieldBox}>
                    <Box pad="none" className={styles.fieldBox}>
                      <Box pad="none" margin={{ bottom: '20px' }}>
                        <FormField
                          name="email_otp"
                          label={t('emailandSMSVerificationCode.placeholder')}
                        >
                          <TextField
                            type="text"
                            placeholder={t(
                              'emailandSMSVerificationCode.placeholder',
                            )}
                            className={styles.modifiedTextField}
                            margin={{ bottom: '0px' }}
                          />
                        </FormField>
                        <div>{t('emailVerificationMsg', { email })}</div>
                      </Box>
                    </Box>
                    <Box pad="none">
                      <div style={{ marginBottom: '2px' }}>&nbsp;</div>
                      <Button
                        color="primary"
                        type="button"
                        disabled={values.amount <= 0 || values.address == ''}
                        onClick={() =>
                          requestEmailCode(values, currency, 'email')
                        }
                        className={styles.modifiedBtnAddon}
                      >
                        {t('emailOtpButton')}
                      </Button>
                    </Box>
                  </Box>

                  {/* {!isUsingGAuth && (
                    // <RequestWithdrawalCode
                    //   requestMethod={exchangeApi.requestWithdrawalCode}
                    //   messageText={t('emailOtpMessage')}
                    //   buttonText={t('emailOtpButton')}
                    //   successText={t('emailOtpSuccessMessage')}
                    //   successState={emailOtpSuccess}
                    //   handleSuccess={() => setEmailOtpSuccess(true)}
                    // />
                    <WithdrawalFormOtp onSuccess={handleOtpSuccess} t={t} currency={currency} />
                  )} */}
                  {isMobileVerified && (
                    <Box pad="none" className={styles.verificationFieldBox}>
                      <Box pad="none" className={styles.fieldBox}>
                        <Box pad="none" margin={{ bottom: '20px' }}>
                          <FormField
                            name="sms_otp"
                            label={t('smsVerificationCode.placeholder')}
                            margin={{ bottom: '20px' }}
                          >
                            <TextField
                              type="text"
                              placeholder={t('smsVerificationCode.placeholder')}
                              className={styles.modifiedTextField}
                              margin={{ bottom: '0px' }}
                            />
                          </FormField>
                          <div>{t('smsVerificationMsg', { mobileNumber })}</div>
                        </Box>
                      </Box>
                      <Box pad="none">
                        <div style={{ marginBottom: '2px' }}>&nbsp;</div>
                        <Button
                          color="primary"
                          type="button"
                          disabled={values.amount <= 0 || values.address == ''}
                          onClick={() =>
                            requestEmailCode(values, currency, 'sms')
                          }
                          className={styles.modifiedBtnAddon}
                        >
                          {t('emailOtpButton')}
                        </Button>
                      </Box>
                    </Box>
                  )}

                  {isUsingGAuth && (
                    <FormField
                      name="gauth_code"
                      label={translate('forms.common.gAuth')}
                    >
                      <TextField
                        type="text"
                        placeholder={translate('forms.common.gAuth')}
                      />
                    </FormField>
                  )}

                  <WithdrawalHeadingValue
                    heading={translate('wallet.withdrawals.serviceCharge')}
                    value={
                      <span>
                        {formatNumberToPlaces(serviceCharge)}&nbsp;{currency}
                        &nbsp;~&nbsp;
                        <FiatConvert
                          currencyCode={currencyCode}
                          balance={serviceCharge}
                          currency={currency}
                        />
                      </span>
                    }
                  />

                  <WithdrawalHeadingValue
                    heading={translate('wallet.withdrawals.willReceive')}
                    value={
                      <span>
                        {formatNumberToPlaces(willReceive)}&nbsp;{currency}
                        &nbsp;~&nbsp;
                        <FiatConvert
                          currencyCode={currencyCode}
                          balance={willReceive}
                          currency={currency}
                        />
                      </span>
                    }
                  />

                  <WithdrawalHeadingValue
                    heading={translate('wallet.withdrawals.balanceAfter')}
                    value={
                      <span>
                        {formatNumberToPlaces(balanceAfter)}&nbsp;{currency}
                        &nbsp;~&nbsp;
                        <FiatConvert
                          currencyCode={currencyCode}
                          balance={balanceAfter}
                          currency={currency}
                        />
                      </span>
                    }
                  />

                  {isNotabene && amountInUsd > 999 && (
                    <Box
                      direction="column"
                      border={{ color: 'brand', size: 'medium' }}
                      pad="small"
                      width={{ min: 'small', max: 'medium' }}
                      style={{ marginBottom: '5px' }}
                    >
                      <Box pad="xsmall">
                        <Heading margin="none" size="string">
                          Additional Information Required
                        </Heading>
                      </Box>
                      <Box pad="xsmall">
                        Due to regulations, we're required to collect additional
                        information about the recipient
                      </Box>
                      <Button
                        color="primary"
                        type="button"
                        onClick={() => setModalNotabene(!modalNotabene)}
                        disabled={canOpenForm}
                      >
                        {translate('buttons.continue')}
                      </Button>
                    </Box>
                  )}
                  {isNotabene &&
                    !isValid &&
                    !values.isVerified &&
                    amountInUsd > 999 && (
                      <Box pad="none" style={{ marginBottom: '5px' }}>
                        <Heading margin="none" size="string" color="#db2327">
                          beneficiary not verified
                        </Heading>
                      </Box>
                    )}
                  <Box pad="none" margin={{ bottom: 'small' }}>
                    <CheckBox
                      name="IVeReadAndAcceptTheTermsOfService"
                      checked={values.IVeReadAndAcceptTheTermsOfService}
                      label={
                        <Text size={'14px'} weight={'bold'}>
                          {t(
                            'travelRuleRequest.IVeReadAndAcceptTheTermsOfService',
                          )}
                        </Text>
                      }
                      onChange={data => {
                        setFieldValue(
                          'IVeReadAndAcceptTheTermsOfService',
                          data,
                        );
                      }}
                    />
                  </Box>
                  <Box pad="0" margin={{ bottom: '6px' }}>
                    <Text size="small">
                      {t('travelRuleRequest.TheTermsOfService')}
                    </Text>
                  </Box>

                  <Button
                    color="primary"
                    type="submit"
                    disabled={balanceAfter < 0 || (values.amount || 0) <= 0}
                  >
                    {translate('buttons.submit')}
                  </Button>

                  <ConfirmModal
                    show={modalOpen}
                    onClose={closeModal}
                    confirm={() => {
                      if (isMobileVerified) {
                        values.sms_token = smsToken;
                      }
                      confirmWithdrawal(
                        {
                          ...values,
                          amount: willReceive,
                          email_token: emailToken,
                          currency,
                        },
                        resetForm,
                        setFieldValue,
                      );
                    }}
                    title={t('confirmWithdrawal')}
                  >
                    <WithdrawalHeadingValue
                      heading={translate(
                        'wallet.withdrawals.withdrawalAddress',
                      )}
                      value={values.address}
                    />

                    {hasAddressTag && (
                      <WithdrawalHeadingValue
                        heading={translate('wallet.withdrawals.withdrawalTag')}
                        value={values.addressTag}
                      />
                    )}

                    <WithdrawalHeadingValue
                      heading={translate('wallet.withdrawals.withdrawalAmount')}
                      value={`${formatNumberToPlaces(
                        values.amount,
                      )} ${currency}`}
                    />

                    <WithdrawalHeadingValue
                      heading={translate('wallet.withdrawals.serviceCharge')}
                      value={`${formatNumberToPlaces(serviceCharge)}
                      ${currency}`}
                    />

                    <WithdrawalHeadingValue
                      heading={translate('wallet.withdrawals.willReceive')}
                      value={`${formatNumberToPlaces(willReceive)}
                      ${currency}`}
                    />

                    <WithdrawalHeadingValue
                      heading={translate('wallet.withdrawals.balanceAfter')}
                      value={`${formatNumberToPlaces(balanceAfter)}
                          ${currency}`}
                    />
                  </ConfirmModal>
                  {isNotabene && (
                    <ConfirmModal
                      show={modalNotabene}
                      onClose={closeModalNotabene}
                      confirm={() => validateBeneficiary(values, setFieldValue)}
                      // confirm={() => {
                      //   console.log(values);
                      // const { data } = await instance({
                      //   url: '/api/forgot-password-otp',
                      //   method: 'POST',
                      //   data: values,
                      // });
                      // }}
                      title={t('Transfer Details')}
                    >
                      <Box pad="20px" align="center">
                        <Text size="large">
                          Additional Information Required
                        </Text>
                      </Box>
                      <Box pad="20px" align="center">
                        <Text size="small">
                          Due to regulations, we're required to collect
                          additional information about the recipient
                        </Text>
                      </Box>
                      {/* <Box pad="none" margin={{ bottom: '30px' }}>
                      <CheckBox
                        name="travelRuleRequest.ItsMyAccount"
                        value={values.travelRuleRequest.ItsMyAccount}
                        label={
                          <Text size={'14px'} weight={'bold'}>
                            {t('travelRuleRequest.ItsMyAccount')}
                          </Text>
                        }
                        onChange={data => {
                          setFieldValue('travelRuleRequest.ItsMyAccount', data);
                        }}
                      />
                    </Box> */}
                      {notabene?.enabled && (
                        <>
                          <Text
                            size={'14px'}
                            weight={'bold'}
                            margin={{ bottom: 'xsmall' }}
                          >
                            {t('travelRuleRequest.beneficiaryVASPdid')}
                          </Text>
                          <ReactSelect
                            name="travelRuleRequest.beneficiaryVASPdid"
                            options={vaspsDids}
                            value={{
                              label:
                                values.travelRuleRequest.beneficiaryVASPname,
                              value:
                                values.travelRuleRequest.beneficiaryVASPdid,
                            }}
                            onChange={data => {
                              setFieldValue(
                                'travelRuleRequest.beneficiaryVASPname',
                                data.label,
                              );
                              setFieldValue(
                                'travelRuleRequest.beneficiaryVASPdid',
                                data.value,
                              );
                            }}
                          />
                        </>
                      )}

                      {!values.travelRuleRequest.ItsMyAccount && (
                        <>
                          <Columns className={styles.columnsRow}>
                            <Columns.Column className={styles.columnsCol}>
                              <FormField
                                value={
                                  values.travelRuleRequest
                                    .BeneficiaryGeographicAddress
                                }
                                name="travelRuleRequest.BeneficiaryGeographicAddress"
                                label={t(
                                  'travelRuleRequest.BeneficiaryGeographicAddress',
                                )}
                              >
                                <TextField
                                  type="text"
                                  placeholder={t(
                                    'travelRuleRequest.BeneficiaryGeographicAddress',
                                  )}
                                  disabled={addressWhitelistEnabled}
                                />
                              </FormField>
                            </Columns.Column>
                            <Columns.Column className={styles.columnsCol}>
                              <Text
                                size={'14px'}
                                weight={'bold'}
                                margin={{ bottom: 'xsmall' }}
                                style={{ marginBottom: 8, display: 'block' }}
                              >
                                {t(
                                  'travelRuleRequest.BeneficiaryCountryOfResidence',
                                )}
                              </Text>
                              <ReactSelect
                                name="travelRuleRequest.BeneficiaryCountryOfResidence"
                                value={{
                                  label:
                                    values.travelRuleRequest
                                      .BeneficiaryCountryOfResidenceName,
                                  value:
                                    values.travelRuleRequest
                                      .BeneficiaryCountryOfResidence,
                                }}
                                options={renderCountryOptions()}
                                onChange={data => {
                                  setFieldValue(
                                    'travelRuleRequest.BeneficiaryCountryOfResidence',
                                    data.value,
                                  );
                                  setFieldValue(
                                    'travelRuleRequest.BeneficiaryCountryOfResidenceName',
                                    data.label,
                                  );
                                }}
                              />
                            </Columns.Column>
                          </Columns>
                          <Box pad="none" margin={{ bottom: 'small' }}>
                            <CheckBox
                              name="travelRuleRequest.IConfirmBeneficiaryAddressIsCorrect"
                              checked={
                                values.travelRuleRequest
                                  .IConfirmBeneficiaryAddressIsCorrect
                              }
                              label={
                                <Text size={'14px'} weight={'bold'}>
                                  {t(
                                    'travelRuleRequest.IConfirmBeneficiaryAddressIsCorrect',
                                  )}
                                </Text>
                              }
                              onChange={data => {
                                setFieldValue(
                                  'travelRuleRequest.IConfirmBeneficiaryAddressIsCorrect',
                                  data,
                                );
                              }}
                            />
                          </Box>
                        </>
                      )}
                    </ConfirmModal>
                  )}
                </Form>
              );
            }}
          </Formik>
        ) : null}
      </Box>
    </React.Fragment>
  );
};

const mapStateToProps = ({
  portfolio: { addresses, portfolios },
  exchangeSettings: {
    currencyCode,
    settings: { notabene, transactions },
  },
  auth: { gAuthEnabled },
  user: {
    profile: { isMobileVerified, email, mobileNumber },
  },
}) => ({
  addresses,
  portfolios,
  currencyCode,
  gAuthEnabled,
  isMobileVerified,
  email,
  mobileNumber,
  notabene,
  transactions,
});

export default withRouter(
  withTranslation()(
    connect(mapStateToProps, {
      getWithdrawalHistory,
      withdrawalRequest,
      triggerToast,
    })(Withdrawals),
  ),
);
