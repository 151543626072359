// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/* Bulma Utilities */\n@media screen and (max-width: 768px) {\n  .currency_flxflow__1Mv1f {\n    flex-flow: wrap;\n  }\n}", "",{"version":3,"sources":["webpack://../../node_modules/bulma/sass/utilities/_all.sass","webpack://src/components/CurrencyInfo/currency.module.scss"],"names":[],"mappings":"AAAA,oBAAA;ACII;EADJ;IAEM,eAAA;EADJ;AACF","sourcesContent":["/* Bulma Utilities */\n@charset \"utf-8\"\n\n@import \"initial-variables\"\n@import \"functions\"\n@import \"derived-variables\"\n@import \"mixins\"\n@import \"controls\"\n@import \"extends\"\n","@import '~styles/variables';\n@import '~bulma/sass/utilities/_all';\n\n.flxflow{\n    @media screen and (max-width: 768px) {\n      flex-flow: wrap;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"flxflow": "currency_flxflow__1Mv1f"
};
export default ___CSS_LOADER_EXPORT___;
