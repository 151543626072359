import React from 'react';
import { NavbarItem } from 'components/Navbar';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { nestedTranslate } from 'utils/strings';
import { Button, Text } from 'components/Wrapped';

const AuthenticatedNav = ({
  topNav,
  t: translate,
  sideNav = false,
  enableDSSO,
}) => {
  const t = nestedTranslate(translate, 'navbar.links');
  const buttonProps = sideNav
    ? { fill: 'horizontal', margin: { vertical: 'xsmall' } }
    : {};

  return (
    <React.Fragment>
      {enableDSSO && (
        <NavbarItem
          to="/derivatives"
          // navIcon={require('assets/icons/person.svg')}
        >
          {t('derivatives')}
        </NavbarItem>
      )}
      <NavbarItem
        to="/account"
        // navIcon={require('assets/icons/person.svg')}
      >
        {t('account')}
      </NavbarItem>
      <React.Fragment>
        <NavbarItem
          to="/orders"
          // navIcon={require('assets/icons/search.svg')}
        >
          {t('orders')}
        </NavbarItem>

        <NavbarItem
          to="/wallet"
          // navIcon={require('assets/icons/wallet.svg')}
        >
          {t('wallet')}
        </NavbarItem>
      </React.Fragment>

      {!!topNav && (
        <Link to="/logout">
          <Button
            color="primary"
            primary={false}
            margin={{ horizontal: 'small' }}
            {...buttonProps}
          >
            {t('logout')}
          </Button>
        </Link>
      )}
    </React.Fragment>
  );
};

export default withTranslation()(AuthenticatedNav);
