// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/* Bulma Utilities */\n.Bank_verificationFieldBox__1opuO {\n  display: flex !important;\n  flex-direction: row !important;\n  justify-content: flex-start !important;\n}\n.Bank_verificationFieldBox__1opuO .Bank_fieldBox__3XF14 {\n  flex-grow: 1;\n}\n\n.Bank_modifiedBtnAddon__2Q7E5 {\n  border-radius: 3px !important;\n  border-top-left-radius: 0px !important;\n  border-bottom-left-radius: 0px !important;\n  height: 41px !important;\n}\n\n.Bank_modifiedTextField__2jTnr {\n  border-top-right-radius: 0px !important;\n  border-bottom-right-radius: 0px !important;\n}", "",{"version":3,"sources":["webpack://../../node_modules/bulma/sass/utilities/_all.sass","webpack://src/containers/Banks/Bank.module.scss"],"names":[],"mappings":"AAAA,oBAAA;ACIA;EACI,wBAAA;EACA,8BAAA;EACA,sCAAA;AAFJ;AAII;EACE,YAAA;AAFN;;AAME;EACE,6BAAA;EACA,sCAAA;EACA,yCAAA;EACA,uBAAA;AAHJ;;AAME;EACE,uCAAA;EACE,0CAAA;AAHN","sourcesContent":["/* Bulma Utilities */\n@charset \"utf-8\"\n\n@import \"initial-variables\"\n@import \"functions\"\n@import \"derived-variables\"\n@import \"mixins\"\n@import \"controls\"\n@import \"extends\"\n","\n@import '~styles/variables';\n@import '~bulma/sass/utilities/_all';\n\n.verificationFieldBox {\n    display: flex !important;\n    flex-direction: row !important;\n    justify-content: flex-start !important;\n  \n    .fieldBox {\n      flex-grow: 1;\n    }\n  }\n  \n  .modifiedBtnAddon {\n    border-radius: 3px !important;\n    border-top-left-radius: 0px !important;\n    border-bottom-left-radius: 0px !important;\n    height: 41px !important;\n  }\n  \n  .modifiedTextField {\n    border-top-right-radius: 0px  !important;\n      border-bottom-right-radius: 0px !important;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"verificationFieldBox": "Bank_verificationFieldBox__1opuO",
	"fieldBox": "Bank_fieldBox__3XF14",
	"modifiedBtnAddon": "Bank_modifiedBtnAddon__2Q7E5",
	"modifiedTextField": "Bank_modifiedTextField__2jTnr"
};
export default ___CSS_LOADER_EXPORT___;
