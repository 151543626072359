import {
  CLEAR_EXCHANGE,
  UPDATE_ORDER_BOOK,
  UPDATE_TRADING_PAIR,
  UPDATE_TRADE_HISTORY,
  UPDATE_ORDER_BOOK_BIDS,
  UPDATE_ORDER_BOOK_ASKS,
  UPDATE_OKX_ORDER_BOOK_ASKS,
  UPDATE_OKX_ORDER_BOOK_BIDS,
  UPDATE_DEPTH_CHART,
  UPDATE_ORDER_BOOK_SELECTION,
  UPDATE_ORDER_BOOK_TOTAL,
} from 'redux/actions/exchange';

import { UPDATE_EXCHANGE_DATA } from 'redux/actions/markets';

const initialState = {
  isLoading: true,
  okxOrderBookAsk: [],
  okxOrderBookBid: [],
  okxOrderBookAskMaxVolume: 0,
  okxOrderBookBidMaxVolume: 0,
  orderBookAsk: [],
  orderBookBid: [],
  orderBookAskMaxVolume: 0,
  orderBookBidMaxVolume: 0,
  tradeHistory: null,
  depthChartData: null,
  tradingPair: {},
  tradingPairStats: {},
  tradingPairSettings: {},
  orderBookSelected: { rate: 0, volume: 0, stop: 0 },
  orderBookTotal: {
    bidBaseCurrency: 0,
    bidQuoteCurrency: 0,
    askBaseCurrency: 0,
    askQuoteCurrency: 0,
  },
};

export const exchangeReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_EXCHANGE:
      return {
        ...initialState,
        orderBookSelected: { ...initialState.orderBookSelected },
      };
    case UPDATE_DEPTH_CHART:
      return { ...state, depthChartData: action.payload };
    case UPDATE_TRADING_PAIR:
      return { ...state, ...action.payload };
    case UPDATE_ORDER_BOOK:
      return {
        ...state,
        orderBookAsk: action.payload.asks,
        orderBookBid: action.payload.bids,
      };
    case UPDATE_OKX_ORDER_BOOK_ASKS:
      const apobAsk = action.payload.orderBook;
      const sobAsk = state.okxOrderBookAsk;
      apobAsk?.map(data => {
        var index = sobAsk.findIndex(x => x.price === data.price);
        if (index > -1) {
          sobAsk[index] = data;
        } else {
          sobAsk.push(data);
        }
      });
      return {
        ...state,
        okxOrderBookAsk: [...sobAsk].slice(-50),
        okxOrderBookAskMaxVolume: action.payload.maxVolume,
      };
    case UPDATE_OKX_ORDER_BOOK_BIDS:
      const apobBids = action.payload.orderBook;
      const sobBids = state.okxOrderBookBid;
      apobBids?.map(data => {
        var index = sobBids.findIndex(x => x.price === data.price);
        if (index > -1) {
          sobBids[index] = data;
        } else {
          sobBids.push(data);
        }
      });
      return {
        ...state,
        okxOrderBookBid: [...sobBids].slice(-50),
        okxOrderBookBidMaxVolume: action.payload.maxVolume,
      };
    case UPDATE_ORDER_BOOK_BIDS:
      return {
        ...state,
        orderBookBid: action.payload.orderBook,
        orderBookBidMaxVolume: action.payload.maxVolume,
      };
    case UPDATE_ORDER_BOOK_ASKS:
      return {
        ...state,
        orderBookAsk: action.payload.orderBook,
        orderBookAskMaxVolume: action.payload.maxVolume,
      };
    case UPDATE_TRADE_HISTORY:
      return { ...state, tradeHistory: action.payload };
    case UPDATE_EXCHANGE_DATA:
      return { ...state, tradingPairStats: action.payload };
    case UPDATE_ORDER_BOOK_SELECTION:
      return {
        ...state,
        orderBookSelected: {
          ...action.payload,
          orderBookSelectedTimestamp: Date.now(),
        },
      };

    case UPDATE_ORDER_BOOK_TOTAL:
      return { ...state, orderBookTotal: action.payload };

    default:
      return state;
  }
};
