// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/* Bulma Utilities */\n.TradingReq_customizedColumns__2s7Ty {\n  margin-top: 0px !important;\n}\n\n.TradingReq_customizedColumns__2s7Ty:last-child {\n  margin-bottom: 0px !important;\n}\n\n.TradingReq_customizedColumns__2s7Ty:not(:last-child) {\n  margin-bottom: 0px !important;\n}\n\n.TradingReq_customizedColumn__1yveA {\n  padding: 0rem 0.75rem !important;\n  text-align: inherit;\n}\n\n@media screen and (max-width: 450px) {\n  .TradingReq_customizedColumn__1yveA {\n    padding: 0rem 0.75rem !important;\n    text-align: inherit;\n  }\n}", "",{"version":3,"sources":["webpack://../../node_modules/bulma/sass/utilities/_all.sass","webpack://src/containers/TradingRequirements/TradingReq.module.scss"],"names":[],"mappings":"AAAA,oBAAA;ACGA;EACI,0BAAA;AADJ;;AAIE;EACE,6BAAA;AADJ;;AAIE;EACE,6BAAA;AADJ;;AAIE;EACE,gCAAA;EACA,mBAAA;AADJ;;AAIE;EACE;IACI,gCAAA;IACA,mBAAA;EADN;AACF","sourcesContent":["/* Bulma Utilities */\n@charset \"utf-8\"\n\n@import \"initial-variables\"\n@import \"functions\"\n@import \"derived-variables\"\n@import \"mixins\"\n@import \"controls\"\n@import \"extends\"\n","@import '~styles/variables';\n@import '~bulma/sass/utilities/_all';\n\n.customizedColumns  {\n    margin-top: 0px !important;\n  }\n  \n  .customizedColumns:last-child {\n    margin-bottom: 0px !important;\n  }\n  \n  .customizedColumns:not(:last-child) {\n    margin-bottom: 0px !important;\n  }\n  \n  .customizedColumn {\n    padding: 0rem 0.75rem !important;\n    text-align: inherit;\n  }\n  \n  @media screen and (max-width: 450px) {\n    .customizedColumn {\n        padding: 0rem 0.75rem !important;\n        text-align: inherit;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"customizedColumns": "TradingReq_customizedColumns__2s7Ty",
	"customizedColumn": "TradingReq_customizedColumn__1yveA"
};
export default ___CSS_LOADER_EXPORT___;
