import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Columns } from 'react-bulma-components';
import { Heading, Message, Paragraph, Box } from 'components/Wrapped';
import { togglePriceChangeAlert } from 'redux/actions/profile';
import { nestedTranslate } from 'utils/strings';
import Switch from 'react-switch';

class PriceChangeAlert extends React.Component {
  componentDidMount() {}

  render() {
    const {
      t: translate,
      profile: { priceChangeAlert, priceChangePercentage },
      togglePriceChangeAlert,
    } = this.props;
    const t = nestedTranslate(translate, 'account.priceAlerts');
    return (
      <React.Fragment>
        <Message background="background-4" margin={{ vertical: 'small' }}>
          <Columns>
            <Columns.Column>
              <Heading level={3}>{t('heading')}</Heading>
            </Columns.Column>
            <Columns.Column>
              <div className="field">
                <Switch
                  onChange={() => togglePriceChangeAlert(priceChangeAlert)}
                  checked={priceChangeAlert}
                  // offColor="#08f"
                  // onColor="#0ff"
                  // offHandleColor="#0ff"
                  // onHandleColor="#08f"
                  handleDiameter={20}
                  height={30}
                  width={60}
                  borderRadius={6}
                  uncheckedIcon={false}
                  checkedIcon={false}
                />
                {/* <input
                  type="checkbox"
                  id="priceAlertSwitch"
                  name="priceAlertSwitch"
                  className="switch"
                  defaultChecked={priceChangeAlert}
                  onClick={() => togglePriceChangeAlert(priceChangeAlert)}
                /> */}
                <label htmlFor="priceAlertSwitch" style={{ marginLeft: 10 }}>
                  {priceChangeAlert ? t('unsubscribe') : t('subscribe')}
                </label>
              </div>
            </Columns.Column>
          </Columns>
          <Paragraph>{t('subHeading', { priceChangePercentage })}</Paragraph>
        </Message>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ user }) => ({
  profile: user.profile,
});

const mapDispatchToProps = {
  togglePriceChangeAlert,
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(PriceChangeAlert),
);
