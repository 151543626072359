const darkNavyTheme = {
  global: {
    colors: {
      brand: '#104dff', // Electric Blue (keep as is)
      control: '#104dff',
      red: '#ff415f', // Keep as is
      green: '#11e5a3', // Keep as is
      white: '#FFFFFF',
      whiteHover: 'rgba(255, 255, 255, 0.1)',
      bidColor: '#11e5a3',
      askColor: '#ff415f',
      background: '#09111F', // Darkest navy, almost black
      primary: '#104dff',
      primaryHover: 'rgba(16, 77, 255, 0.7)',
      loadingColor: '#104dff',
      focus: '#104dff',
      'background-1': '#09111F', // Darkest navy
      'background-2': '#0E1623', // Slightly lighter navy
      'background-3': '#131C2E', // Medium navy
      'background-4': '#182338', // Lighter navy
      'background-5': '#1D2A42', // Lightest navy (for contrast when needed)
      'border-1': 'rgba(255, 255, 255, 0.1)',
      handleColor: '#104dff',
      defaultTextColor: '#D3D3D3', // Light gray for text
      defaultTextColorDarken: '#A9A9A9', // Darker gray for less important text
      tableBackground: '#0E1623',
      tableHeadBackground: '#131C2E',
      selectBackground: '#0E1623',
      errorPageBackground: '#09111F',
      footerBackground: '#0E1623',
      navbarBackground: '#0E1623',
      skinnyBarBackground: '#09111F',
      cookieConsentBackground: '#131C2E',
      chartLine: '#104dff', // For chart lines
      chartBackground: '#09111F', // Chart background
    },
    font: {
      size: '14px',
      lineHeight: '1.5',
      family: '"Inter", "Roboto", sans-serif',
    },
  },
  button: {
    color: '#FFFFFF',
    primary: {
      color: '#FFFFFF',
      background: '#104dff',
    },
    secondary: {
      color: '#FFFFFF',
      background: '#161a35',
    },
    border: {
      radius: '4px',
    },
  },
  tab: {
    active: {
      color: 'brand',
    },
    color: 'text',
    border: {
      side: 'bottom',
      size: 'small',
      color: {
        dark: 'text',
        light: 'text',
      },
      active: {
        color: {
          dark: 'brand',
          light: 'brand',
        },
      },
      hover: {
        color: {
          dark: 'brand',
          light: 'brand',
        },
      },
    },
  },
  chartingTheme: 'Dark',
  modalBackgroundColor: '#153256',
  modalConfirmationBackgroundColor: '#1B3D68',
  formButtonColor: 'primary',
};

const themes = [
  {
    themeName: 'Dark Navy',
    theme: darkNavyTheme,
  },
];

window.themes = themes;

export default themes;
