import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import { FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa';
import QRCode from 'qrcode.react';
import { Box, Text } from 'components/Wrapped';
import { Copyright } from 'containers/BottomBar';
import { nestedTranslate } from 'utils/strings';
import { Advisory } from './Advisory';
import { ExternalLink } from 'components/Helpers';

import styles from './BottomBar.module.scss';

const FooterWrapper = styled(Box)`
  background-color: #09111f;
  color: var(--defaultTextColor);
  width: 100%;
  padding: 20px 0;
  overflow-x: hidden;
`;

const FooterContent = styled(Box)`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 2rem;
  justify-content: center;

  @media (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
  }
`;

const FooterSection = styled(Box)`
  display: flex;
  flex-direction: column;
`;

const FooterTitle = styled(Text)`
  font-size: 1.2rem;
  margin-bottom: 1rem;
  font-weight: bold;
  color: #d3d3d3;
`;

const FooterLink = styled(Link)`
  color: #d3d3d3;
  text-decoration: none;
  margin-bottom: 0.5rem;
  transition: color 0.3s ease;
  &:hover {
    color: #11e5a3;
  }
`;

const SocialLinks = styled(Box)`
  display: flex;
  gap: 1rem;
  margin-top: 0.5rem;
`;

const SocialLink = styled(ExternalLink)`
  color: #d3d3d3;
  &:hover {
    color: #11e5a3;
  }
`;

const SocialLinksSection = styled(FooterSection)`
  justify-content: flex-start;
  align-items: flex-start;
`;

const QRCodeWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const QRCodeLabel = styled(Text)`
  margin-top: 0.5rem;
  text-align: center;
  color: #d3d3d3;
`;

const CopyrightWrapper = styled(Box)`
  text-align: center;
  margin-top: 2rem;
  color: #808080;
  padding: 0 20px;
`;

const DisclaimerText = styled(Text)`
  color: #808080;
  font-size: 0.8rem;
  margin-top: 0.5rem;
`;

const BottomBar = ({
  t: translate,
  contentPages,
  supportUrl,
  enableCryptoFeatures,
}) => {
  const t = nestedTranslate(translate, 'footer');

  return (
    <FooterWrapper as="footer" className={styles.bottomBar}>
      <FooterContent>
        <FooterSection>
          <FooterTitle>{t('company')}</FooterTitle>
          <FooterLink to="/about-us">{t('aboutUs')}</FooterLink>
          <FooterLink to="/join-us">{t('joinUs')}</FooterLink>
          <FooterLink to="/affiliate-program">
            {t('affiliateProgram')}
          </FooterLink>
        </FooterSection>

        <FooterSection>
          <FooterTitle>{t('products')}</FooterTitle>
          {enableCryptoFeatures && (
            <FooterLink to="/currencies">{t('currencies')}</FooterLink>
          )}
          <FooterLink to="/asset-status">{t('assetStatus')}</FooterLink>
          <FooterLink to="/trading">{t('trading')}</FooterLink>
        </FooterSection>

        <FooterSection>
          <FooterTitle>{t('support')}</FooterTitle>
          <FooterLink to="/fees">{t('exchangeFees')}</FooterLink>
          <FooterLink to="/trading-rules">{t('tradingRules')}</FooterLink>
          <FooterLink to="/exchange-documentation">
            {t('apiDocumentation')}
          </FooterLink>
          <FooterLink to="/contact-us">{t('contactUs')}</FooterLink>
        </FooterSection>

        <SocialLinksSection>
          <SocialLinks>
            <SocialLink href="#">
              <FaTwitter size={24} />
            </SocialLink>
            <SocialLink href="#">
              <FaInstagram size={24} />
            </SocialLink>
            <SocialLink href="#">
              <FaLinkedin size={24} />
            </SocialLink>
          </SocialLinks>
        </SocialLinksSection>
      </FooterContent>

      <CopyrightWrapper>
        <Copyright exchangeName="SINEGY DAX Sdn.Bhd. © (1424796-U)" />
        <DisclaimerText>
          Disclaimer: The purchase of digital currencies is conducted through
          SINEGY DAX Sdn.Bhd. © (1424796-U). By accessing this site, you agree
          to be bound by its Terms of Service and Privacy Policy.
        </DisclaimerText>
        <DisclaimerText>
          Digital currencies are not currently backed nor supported by any
          government or central bank. The value of digital currencies is
          determined by economy of supply and demand, and they are more volatile
          than traditional currencies. Trading in digital currencies comes with
          significant risks, including volatile market price swings or flash
          crashes, market manipulation, and cybersecurity risks. Digital
          currency trading requires knowledge of digital currency markets. In
          attempting to profit through digital currency trading, you must
          compete with traders worldwide. You should have appropriate knowledge
          and experience before engaging in substantial digital currency
          trading.
        </DisclaimerText>
        <DisclaimerText>
          For more information please check out the{' '}
          <ExternalLink href="https://www.sc.com.my/investor-alert-list">
            Securities Commission Malaysia's Investor Alert List
          </ExternalLink>
          .
        </DisclaimerText>
      </CopyrightWrapper>
    </FooterWrapper>
  );
};

const mapStateToProps = ({
  exchangeSettings: {
    settings: { supportUrl, enableCryptoFeatures },
    contentPages,
  },
}) => ({
  contentPages,
  supportUrl,
  enableCryptoFeatures,
});

export default withTranslation()(connect(mapStateToProps)(BottomBar));
