import React from 'react';
import styled from 'styled-components';
import { FaTwitter, FaFacebook, FaLinkedin } from 'react-icons/fa';
import QRCode from 'qrcode.react';
import { Box, Text } from 'components/Wrapped';

const FooterWrapper = styled(Box)`
  background-color: var(--background-1);
  color: var(--defaultTextColor);
  width: 100%;
  padding: 40px 0;
`;

const FooterContent = styled(Box)`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 2rem;
`;

const FooterSection = styled(Box)`
  display: flex;
  flex-direction: column;
`;

const FooterTitle = styled(Text)`
  font-size: 1.2rem;
  margin-bottom: 1rem;
  font-weight: bold;
`;

const FooterLink = styled.a`
  color: var(--defaultTextColor);
  text-decoration: none;
  margin-bottom: 0.5rem;
  transition: color 0.3s ease;
  &:hover {
    color: var(--primary);
  }
`;

const SocialLinks = styled(Box)`
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 1rem;
`;

const QRCodeWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const QRCodeLabel = styled(Text)`
  margin-top: 1rem;
  text-align: center;
`;

const Footer = () => {
  return (
    <FooterWrapper>
      <FooterContent>
        <FooterSection>
          <FooterTitle>Company</FooterTitle>
          <FooterLink href="#">About Us</FooterLink>
          <FooterLink href="#">Careers</FooterLink>
          <FooterLink href="#">Contact</FooterLink>
        </FooterSection>

        <FooterSection>
          <FooterTitle>Products</FooterTitle>
          <FooterLink href="#">Spot Trading</FooterLink>
          <FooterLink href="#">Futures</FooterLink>
          <FooterLink href="#">Staking</FooterLink>
        </FooterSection>

        <FooterSection>
          <FooterTitle>Support</FooterTitle>
          <FooterLink href="#">Help Center</FooterLink>
          <FooterLink href="#">API Documentation</FooterLink>
          <FooterLink href="#">Terms of Service</FooterLink>
          <FooterLink href="#">Privacy Policy</FooterLink>
        </FooterSection>

        <FooterSection>
          <QRCodeWrapper>
            <QRCode
              value="https://yourappdownloadlink.com"
              size={150}
              bgColor="transparent"
              fgColor="var(--defaultTextColor)"
            />
            <QRCodeLabel>Scan to download our mobile app</QRCodeLabel>
          </QRCodeWrapper>
          <SocialLinks>
            <FooterLink href="#">
              <FaTwitter size={24} />
            </FooterLink>
            <FooterLink href="#">
              <FaFacebook size={24} />
            </FooterLink>
            <FooterLink href="#">
              <FaLinkedin size={24} />
            </FooterLink>
          </SocialLinks>
        </FooterSection>
      </FooterContent>
      <Box margin={{ top: 'medium' }} align="center">
        <Text size="small">© 2023 SINEGY. All rights reserved.</Text>
      </Box>
    </FooterWrapper>
  );
};

export default Footer;
