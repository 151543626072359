// const {
//   setup: { backendUrl, secret, websocketUrl, tsApiUrl },
// } = window;

// export { backendUrl, secret, websocketUrl, tsApiUrl };

const hostname = 'api-gateway.sinegy.my';
export const backendUrl = `https://${hostname}`;
export const websocketUrl = `wss://${hostname}/ws`;
export const tsApiUrl = `https://${hostname}`;
export const secret = window.secret;