// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Controls_multilineText__2U_Wi {\n  white-space: pre-wrap;\n}", "",{"version":3,"sources":["webpack://src/pages/SocialTrade/Controls.module.scss"],"names":[],"mappings":"AAAA;EACI,qBAAA;AACJ","sourcesContent":[".multilineText {\n    white-space: pre-wrap;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"multilineText": "Controls_multilineText__2U_Wi"
};
export default ___CSS_LOADER_EXPORT___;
