// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/* Bulma Utilities */\n.AddressBook_verificationFieldBox__2de8x {\n  display: flex !important;\n  flex-direction: row !important;\n  justify-content: flex-start !important;\n}\n.AddressBook_verificationFieldBox__2de8x .AddressBook_fieldBox__2F4lJ {\n  flex-grow: 1;\n}\n\n.AddressBook_modifiedBtnAddon__13YvH {\n  border-radius: 3px !important;\n  border-top-left-radius: 0px !important;\n  border-bottom-left-radius: 0px !important;\n  height: 41px !important;\n}\n\n.AddressBook_modifiedTextField__2qkXo {\n  border-top-right-radius: 0px !important;\n  border-bottom-right-radius: 0px !important;\n}", "",{"version":3,"sources":["webpack://../../node_modules/bulma/sass/utilities/_all.sass","webpack://src/containers/AddressBook/AddressBook.module.scss"],"names":[],"mappings":"AAAA,oBAAA;ACGA;EACI,wBAAA;EACA,8BAAA;EACA,sCAAA;AADJ;AAGI;EACE,YAAA;AADN;;AAKE;EACE,6BAAA;EACA,sCAAA;EACA,yCAAA;EACA,uBAAA;AAFJ;;AAKE;EACE,uCAAA;EACE,0CAAA;AAFN","sourcesContent":["/* Bulma Utilities */\n@charset \"utf-8\"\n\n@import \"initial-variables\"\n@import \"functions\"\n@import \"derived-variables\"\n@import \"mixins\"\n@import \"controls\"\n@import \"extends\"\n","@import '~styles/variables';\n@import '~bulma/sass/utilities/_all';\n\n.verificationFieldBox {\n    display: flex !important;\n    flex-direction: row !important;\n    justify-content: flex-start !important;\n  \n    .fieldBox {\n      flex-grow: 1;\n    }\n  }\n  \n  .modifiedBtnAddon {\n    border-radius: 3px !important;\n    border-top-left-radius: 0px !important;\n    border-bottom-left-radius: 0px !important;\n    height: 41px !important;\n  }\n  \n  .modifiedTextField {\n    border-top-right-radius: 0px  !important;\n      border-bottom-right-radius: 0px !important;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"verificationFieldBox": "AddressBook_verificationFieldBox__2de8x",
	"fieldBox": "AddressBook_fieldBox__2F4lJ",
	"modifiedBtnAddon": "AddressBook_modifiedBtnAddon__13YvH",
	"modifiedTextField": "AddressBook_modifiedTextField__2qkXo"
};
export default ___CSS_LOADER_EXPORT___;
