import React from 'react';
import styled from 'styled-components';
import { Box, Text, Button } from 'components/Wrapped';
import { Text as WrappedText } from 'components/Wrapped';
import { PageWrap } from 'components/Containers';
import AnimatedText from '../../components/AnimatedText';
import OrderBookAnimation from '../../components/OrderBookAnimation';
import Lottie from 'lottie-react';
import { useMediaQuery } from 'react-responsive';
import Footer from './Footer';

import FAQSectionComponent from './FAQSection';

import findOpportunitiesAnimation from '../../assets/animations/magnifier-blue.json';
import analyzeTrendsAnimation from '../../assets/animations/13-blue.json';
import manageTradesAnimation from '../../assets/animations/wallet-blue.json';

const ProximaText = styled(WrappedText)`
  font-family: 'ProximaRegular', 'Open Sans', sans-serif;
`;

const HeroSection = styled(Box)`
  background: linear-gradient(
    135deg,
    var(--background-1) 0%,
    var(--background-2) 100%
  );
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const StyledButton = styled(Button)`
  background-color: var(--primary);
  color: var(--defaultTextColor);
  border: none;
  outline: none;
  padding: 12px 24px;
  font-size: 1.2rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover,
  &:focus,
  &:active {
    background-color: #11e5a3;
    outline: none;
    box-shadow: none;
    border: none;
    border-style: none;
    border-color: transparent;
  }

  &:focus {
    outline: 0;
    outline-offset: 0;
    border-style: none;
    box-shadow: none;
  }

  &::-moz-focus-inner {
    border: 0;
  }

  &::after {
    border: none !important;
  }
`;

const FeatureSection = styled(Box)`
  background-color: var(--background-2);
  padding: 80px 5%;
`;

const FeatureCard = styled(Box)`
  background-color: var(--background-3);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  flex: 1;
  min-width: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;
  border-radius: 10px;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }
`;

const StatBox = styled(Box)`
  background-color: var(--background-3);
  padding: 30px;
  border-radius: 10px;
  text-align: center;
  width: 300px; // Set a fixed width
  margin: 10px; // Add some margin between boxes
  @media (max-width: 768px) {
    width: 90%; // Full width on mobile
    margin: 10px 0;
  }
`;

const VideoSection = styled.div`
  position: relative;
  width: 90%;
  max-width: 1200px;
  margin: -50px auto 50px;
  aspect-ratio: 16 / 9;
  background-color: #2a2a2a; // Brighter silver color
  border-radius: 20px;
  padding: 10px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);

  &::before {
    content: '';
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    width: 10%;
    height: 5px;
    background-color: #2a2a2a;
    border-radius: 10px;
  }
`;

const StyledVideo = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
`;

const NewsSection = styled(Box)`
  background-color: var(--background-1);
`;

const NewsCard = styled(Box)`
  background-color: var(--background-3);
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }
`;

const PromoSection = styled(Box)`
  background-color: #d3d3d3;
  padding: 50px;
  border-radius: 20px;
  margin: 50px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const PromoImage = styled.img`
  max-width: 100%;
  height: auto;
  border-radius: 10px;
`;

const ExperienceSection = styled(Box)`
  background-color: var(--background-2);
`;

const ExperienceCard = styled(Box)`
  background-color: var(--background-3);
  transition: transform 0.3s ease;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;
  border-radius: 10px;

  &:hover {
    transform: translateY(-5px);
  }
`;

const Home = () => {
  const isMobile = useMediaQuery({ maxWidth: 768 });

  return (
    <PageWrap>
      <HeroSection>
        <AnimatedText />
        <ProximaText
          size={isMobile ? 'medium' : 'large'}
          margin={{ vertical: 'medium' }}
          textAlign="center"
        >
          SINEGY is the one-stop destination for advanced crypto traders. <br />
          Access spot, futures trading and staking - all in one powerful
          interface.
        </ProximaText>
        <StyledButton primary label="Get Started" margin={{ top: 'medium' }} />
      </HeroSection>

      <VideoSection>
        <StyledVideo autoPlay loop muted playsInline>
          <source src="/videos/sdx-vid.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </StyledVideo>
      </VideoSection>

      <FeatureSection>
        <ProximaText
          size="xlarge"
          weight="bold"
          textAlign="center"
          margin={{ bottom: 'medium' }}
        >
          Designed for all traders
        </ProximaText>
        <Box
          direction={isMobile ? 'column' : 'row'}
          gap="medium"
          justify="center"
          align="stretch"
        >
          {[
            {
              title: 'Highly customizable',
              description:
                'Choose as many (or as few) trading modules as desired from our comprehensive list.',
            },
            {
              title: 'All-in-one interface',
              description:
                'Switch instantly between spot, margin, futures trading and staking.',
            },
            {
              title: 'Real-time performance',
              description:
                'With live market data fed into a fast and responsive UI.',
            },
          ].map((feature, index) => (
            <FeatureCard key={index}>
              <ProximaText
                size="large"
                weight="bold"
                margin={{ bottom: 'small' }}
              >
                {feature.title}
              </ProximaText>
              <ProximaText textAlign="center">
                {feature.description}
              </ProximaText>
            </FeatureCard>
          ))}
        </Box>
      </FeatureSection>

      <Box pad={isMobile ? 'medium' : 'large'} background="background-1">
        <ProximaText
          size="xlarge"
          weight="bold"
          textAlign="center"
          margin={{ bottom: 'medium' }}
        >
          Confidently plan and execute trades
        </ProximaText>
        <ProximaText textAlign="center" margin={{ bottom: 'medium' }}>
          SINEGY comes fully equipped with must-have advanced trading tools and
          features, addressing all your trading needs in one place.
        </ProximaText>
        <Box
          direction={isMobile ? 'column' : 'row'}
          gap="large"
          justify="center"
        >
          {[
            {
              title: 'Find opportunities',
              description:
                'Quickly browse markets by category; top gainers and losers, top traded and newly listed. Display your favorite markets so they are only one click away.',
              animation: findOpportunitiesAnimation,
            },
            {
              title: 'Analyze trends',
              description:
                'Identify trades with full-featured charting and compare up to 4 markets at once. Monitor the action with live order books and streaming trades.',
              animation: analyzeTrendsAnimation,
            },
            {
              title: 'Manage trades',
              description:
                'Utilize advanced order types and instructions including stop loss, take profit, IOC, reduce-only and trigger options.',
              animation: manageTradesAnimation,
            },
          ].map((feature, index) => (
            <Box key={index} align="center" width="medium">
              <Lottie
                animationData={feature.animation}
                style={{ width: 150, height: 150 }}
              />
              <ProximaText size="large" weight="bold">
                {feature.title}
              </ProximaText>
              <ProximaText textAlign="center">
                {feature.description}
              </ProximaText>
            </Box>
          ))}
        </Box>
      </Box>

      <PromoSection>
        <Box
          direction={isMobile ? 'column' : 'row'}
          align="center"
          justify="center"
        >
          <Box
            width={isMobile ? '50%' : '50%'}
            margin={{ bottom: isMobile ? 'medium' : 'none' }}
          >
            <ProximaText
              Align="center"
              size="xlarge"
              weight="bold"
              color="var(--background-1)"
              margin={{ bottom: 'medium' }}
            >
              Compete for the $100,000 prize pool
            </ProximaText>
            <ProximaText
              Align="center"
              color="var(--background-1)"
              margin={{ bottom: 'medium' }}
            >
              Exclusively for clients new to trading on SINEGY. Register today
              and get $10 credit as trading collateral.
            </ProximaText>
            <StyledButton
              primary
              label="Enter The Race!"
              margin={{ top: 'medium' }}
            />
          </Box>
          <Box width={isMobile ? '100%' : '45%'} align="center">
            <PromoImage src="/images/bino-sdx.png" alt="Prize Pool" />
          </Box>
        </Box>
      </PromoSection>

      <OrderBookAnimation />

      <FeatureSection>
        <Box align="center" pad="large">
          <ProximaText align="center" size="xxlarge" weight="bold">
            Ready to dive in?
          </ProximaText>
          <ProximaText
            align="center"
            size="medium"
            color="text-weak"
            margin={{ vertical: 'medium' }}
          >
            Create an account to unleash the power of the new SINEGY.
            <br />
            It takes as little as 4 minutes to get started.
          </ProximaText>
          <StyledButton
            primary
            label="Get started"
            margin={{ top: 'medium', bottom: 'large' }}
          />
        </Box>

        <Box align="center" pad="large">
          <ProximaText
            align="center"
            size="xlarge"
            weight="bold"
            margin={{ bottom: 'medium' }}
          >
            Join the most trader-centric
            <br />
            crypto community in the digital asset industry.
          </ProximaText>

          <Box
            direction={isMobile ? 'column' : 'row'}
            gap="medium"
            justify="center"
            align="center"
            wrap
          >
            <StatBox>
              <ProximaText size="xxlarge" weight="bold">
                20K+
              </ProximaText>
              <ProximaText size="small" color="text-weak">
                Unique Verified Clients
              </ProximaText>
              <ProximaText size="xsmall" color="text-weak">
                ALL TIME
              </ProximaText>
            </StatBox>
            <StatBox>
              <ProximaText size="xxlarge" weight="bold">
                $78M+
              </ProximaText>
              <ProximaText size="small" color="text-weak">
                Trading volume
              </ProximaText>
              <ProximaText size="xsmall" color="text-weak">
                THIS YEAR
              </ProximaText>
            </StatBox>
            <StatBox>
              <ProximaText size="xxlarge" weight="bold">
                &lt; 5 mins
              </ProximaText>
              <ProximaText size="small" color="text-weak">
                Average support resolution time
              </ProximaText>
              <ProximaText size="xsmall" color="text-weak">
                THIS YEAR
              </ProximaText>
            </StatBox>
          </Box>
        </Box>
      </FeatureSection>

      <FAQSectionComponent />
    </PageWrap>
  );
};

export default Home;
