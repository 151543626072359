import React, { useState } from 'react';
import styled from 'styled-components';
import { Box, Text } from 'components/Wrapped';
import { FaChevronDown } from 'react-icons/fa';

const ProximaText = styled(Text)`
  font-family: 'ProximaRegular', 'Open Sans', sans-serif;
`;

const FAQSection = styled(Box)`
  width: 100%;
`;

const FAQContainer = styled(Box)`
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
`;

const FAQItem = styled(Box)`
  margin-bottom: 10px;
  width: 100%;
  overflow: hidden;
`;

const FAQQuestion = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  width: 100%;
  padding: 15px 20px;
  font-family: 'ProximaRegular', 'Open Sans', sans-serif;
`;

const FAQAnswer = styled(Box)`
  max-height: ${props => (props.isOpen ? '500px' : '0')};
  overflow: hidden;
  transition: all 0.5s ease-out;
  padding: ${props => (props.isOpen ? '15px 20px' : '0 20px')};
`;

const FAQToggle = styled(FaChevronDown)`
  transform: ${props => (props.isOpen ? 'rotate(180deg)' : 'rotate(0)')};
  transition: transform 0.3s ease;
  font-size: 18px;
  margin-left: 20px;
  flex-shrink: 0;
`;

const FAQSectionComponent = () => {
  const [openFAQ, setOpenFAQ] = useState(null);

  const toggleFAQ = index => {
    setOpenFAQ(openFAQ === index ? null : index);
  };

  const faqData = [
    {
      question: 'Is this a scam site?',
      answer:
        'Fortunately for you, NO! We are a licensed Digital Asset Exchange under Securities Commission Malaysia. Trade securely with us!',
    },
    {
      question: 'What is a Maker and a Taker?',
      answer:
        "Every trade is made between two parties, the Maker, whose order exists on the order book, and the Taker, who places the order that matches the maker's order.",
    },
    {
      question: 'Can a Corporate sign up for an account?',
      answer:
        'Absolutely! SINEGY is proud to be the trusted choice for many corporates and institutions who trade with us. Sign up with us today!',
    },
    {
      question: 'But this still looks different from the SINEGY I know',
      answer:
        'And you are right! To serve our partners like you better, we are undergoing a major product upgrade. So Trade with us and test the new Exchange out!',
    },
    {
      question: 'There is something else I would like to know.',
      answer:
        'Reach out to our support channels either by emailing support@sinegy.com or drop us a message at https://sinegy.zendesk.com/hc/en-us/requests/new',
    },
  ];

  return (
    <FAQSection background="background-2" pad={{ vertical: 'large' }}>
      <FAQContainer>
        <ProximaText
          size="xxlarge"
          weight="bold"
          textAlign="center"
          margin={{ bottom: 'large' }}
        >
          Want to know more?
        </ProximaText>
        {faqData.map((faq, index) => (
          <FAQItem key={index} background="background-3" round="small">
            <FAQQuestion onClick={() => toggleFAQ(index)}>
              <ProximaText size="medium" weight="bold" style={{ flex: 1 }}>
                {faq.question}
              </ProximaText>
              <FAQToggle isOpen={openFAQ === index} />
            </FAQQuestion>
            <FAQAnswer
              isOpen={openFAQ === index}
              background={openFAQ === index ? 'background-4' : 'transparent'}
            >
              <ProximaText size="small">{faq.answer}</ProximaText>
            </FAQAnswer>
          </FAQItem>
        ))}
      </FAQContainer>
    </FAQSection>
  );
};

export default FAQSectionComponent;
