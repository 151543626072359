import React, { Fragment, useEffect, useState } from 'react';
import _, { isArray } from 'lodash';
import { moment } from 'i18n';
import { connect } from 'react-redux';
import { Copy, Edit, Transaction } from 'grommet-icons';
import i18n from 'i18n';
import * as Yup from 'yup';

import { withTranslation } from 'react-i18next';
import { nestedTranslate } from 'utils/strings';
import { Box, CheckBox, Paragraph } from 'components/Wrapped';
import { TableWrapper, TableSubComponentRow } from 'containers/Tables';
import { ExternalLink } from 'components/Helpers';
import { handleCopyToClipboard } from 'utils';
import { convertCurrency, formatNumberToPlaces } from 'utils/numbers';
import { useMutation } from 'react-query';
import { Button } from 'components/Wrapped';
import { authenticatedInstance, exchangeApi } from 'api';
import { triggerToast } from 'redux/actions/ui';
import { getDepositHistory } from 'redux/actions/portfolio';
import { withRouter } from 'react-router';
import { MessageModal } from 'containers/Modals';
import { Form, Formik } from 'formik';
import styles from './../../pages/Wallet/Wallet.module.scss';
import { Columns } from 'react-bulma-components';
import { DatePicker, FormField, TextField } from 'components/Form';
import { Text } from 'grommet';
import countries from 'i18n-iso-countries';
import { ReactSelect } from 'components/Form/SelectField';

const INTERNAL_TRANSFER = 'Internal transfer';

const CryptoDepositHistoryTable = ({ data, t, transactions }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [depositData, setDepositData] = useState({});
  const closeModal = () => setModalOpen(false);
  let columns = [
    {
      Header: t('depositReqDate'),
      id: 'depositReqDate',
      accessor: ({ depositReqDate: value }) =>
        moment
          .utc(value)
          .local()
          .format('L HH:mm'),
      style: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' },
    },
    {
      Header: t('depositConfirmDate'),
      accessor: 'depositConfirmDate',
      Cell: ({ value }) =>
        moment
          .utc(value)
          .local()
          .format('L HH:mm'),
      style: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' },
    },
    {
      Header: t('depositAmount'),
      id: 'depositAmount',
      accessor: ({ depositAmount, depositType }) =>
        `${formatNumberToPlaces(depositAmount)} ${depositType}`,
      style: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' },
    },
    {
      Header: t('txnHash'),
      id: 'txnHash',
      accessor: ({ txnHash, depositAddress }) =>
        depositAddress === INTERNAL_TRANSFER ? t('internalTransfer') : txnHash,
      Cell: ({ value, original: { explorerURL, depositAddress } }) =>
        value &&
        (depositAddress === INTERNAL_TRANSFER ? (
          value
        ) : (
          <Box
            pad="none"
            direction="row"
            style={{ alignItems: 'center', justifyContent: 'center' }}
          >
            <ExternalLink
              style={{ maxWidth: 75, marginRight: 10 }}
              href={explorerURL}
            >
              <div
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {value}
              </div>
            </ExternalLink>
            <Copy
              size="small"
              onClick={() => handleCopyToClipboard(value)}
              style={{ cursor: 'pointer' }}
            />
          </Box>
        )),
      style: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' },
    },
    {
      Header: t('status'),
      id: 'status',
      accessor: data => {
        const { currentTxnCount, requiredTxnCount, isPassedTravelRule } = data;
        if (!isPassedTravelRule && transactions?.enableTravelRules) {
          return (
            <>
              <Box direction="row" pad="none">
                <Box pad="none">{t('PendingOriginatorInfo')}</Box>
                <Box pad="xsmall">
                  <Edit
                    size="small"
                    onClick={() => {
                      data.depositAmountUsd = convertCurrency(
                        data.depositAmount,
                        {
                          from: data.depositType,
                          to: 'USD',
                        },
                      );
                      setDepositData(data);
                      setModalOpen(true);
                    }}
                    style={{ cursor: 'pointer' }}
                  ></Edit>
                </Box>
              </Box>
            </>
          );
        } else {
          const status =
            currentTxnCount >= requiredTxnCount
              ? t('completed')
              : `${currentTxnCount}/${requiredTxnCount} Confirmations`;
          return status;
        }
      },
      style: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' },
    },
  ];

  const validationSchema = () => {
    const shape = {
      firstName: Yup.string().required(),
      lastName: Yup.string().required(),
    };
    return Yup.lazy(() => {
      if (depositData.depositAmountUsd >= 1000) {
        shape.country = Yup.string().required();
        shape.originatorGeographicAddress = Yup.string().required();
        shape.originatorName = Yup.string();
        shape.originatorNationalIdentification = Yup.string().required();
        shape.originatorNationalIdentifierType = Yup.string().required();
        shape.originatorDoB = Yup.string().required();
        shape.originatorCountryOfBirth = Yup.string().required();
      }
      return Yup.object().shape(shape);
    });
  };
  const setDepositTravelRule = async data => {
    console.log(data);
    try {
      const response = await authenticatedInstance({
        url: '/api/TravelRule/SetDepositTravelRule',
        method: 'POST',
        data: data,
      });
      // triggerToast(error.message, 'error')
      if (response?.data?.status === 'success') {
        triggerToast(response?.data?.data, 'success');
      } else {
        triggerToast(response?.data?.data, 'error');
      }
      closeModal();
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    countries.registerLocale(require('i18n-iso-countries/langs/en.json'));
  }, []);

  const renderCountryOptions = () => {
    const countryList = countries.getNames('en');
    return Object.entries(countryList).map(([countryCode, countryName]) => ({
      value: countryCode,
      label: countryName,
    }));
  };

  const renderNationalIdentifierType = () => {
    return [
      { value: 'ARNU', label: 'Alien registration number' },
      { value: 'CCPT', label: 'Passport number' },
      { value: 'RAID', label: 'Registration authority identifier' },
      { value: 'DRLC', label: 'Driver license number' },
      { value: 'FIIN', label: 'Foreign investment identity number' },
      { value: 'TXID', label: 'Tax identification number' },
      { value: 'SOCS', label: 'Social security number' },
      { value: 'IDCD', label: 'Identity card number' },
      { value: 'LEIX', label: 'Legal Entity Identifier' },
      { value: 'MISC', label: 'Unspecified' },
    ];
  };

  return (
    <>
      <TableWrapper
        data={isArray(data) ? data : []}
        exportable={[
          'depositReqDate',
          'depositConfirmDate',
          'depositAmount',
          'txnHash',
          'status',
        ]}
        fileName="deposit-history"
        isFilterable={true}
        filterBy={['depositAmount']}
        columns={columns}
        // defaultSorted={[
        //   {
        //     id: 'depositConfirmDate',
        //     desc: true,
        //   },
        //   {
        //     id: 'depositReqDate',
        //     desc: true,
        //   },
        // ]}
        minRows={10}
        pageSize={10}
        showPagination={true}
        sortable={false}
        className="-highlight"
        SubComponent={({ original }) => {
          const formattedRowData = Object.entries(original)
            .filter(([key]) => !key.startsWith('_'))
            .map(([key, val]) => {
              return (
                <TableSubComponentRow
                  key={key}
                  type={key}
                  value={val}
                  label={t(key)}
                  address={original?.depositAddress}
                />
              );
            });
          return <Box background="background-3">{formattedRowData}</Box>;
        }}
      />
      <MessageModal
        show={modalOpen}
        onClose={closeModal}
        confirm={() => {
          console.log('is confirm');
        }}
        title={t('OriginatorInformation')}
      >
        <Formik
          initialValues={{
            firstName: '',
            lastName: '',
            depositId: '',
            country: '',
            originatorGeographicAddress: '',
            originatorName: '',
            originatorNationalIdentification: '',
            originatorNationalIdentifierType: '',
            originatorDoB: '',
            originatorCountryOfBirth: '',
            iConfirmThatOriginatorInformationIsCorect: false,
          }}
          validationSchema={validationSchema()}
          onSubmit={values => {
            values.originatorName = values.firstName + ' ' + values.lastName;
            values.depositId = depositData.depositId;
            delete values.firstName;
            delete values.lastName;
            console.log(values);
            setDepositTravelRule(values);
          }}
        >
          {({ values, setFieldValue }) => {
            return (
              <Form>
                <Box>
                  <Columns className={styles.columnsRow}>
                    <Columns.Column className={styles.columnsCol}>
                      <FormField
                        name="firstName"
                        label={t('deposit.firstName')}
                      >
                        <TextField
                          type="text"
                          placeholder={t('deposit.firstName')}
                        />
                      </FormField>
                    </Columns.Column>
                    <Columns.Column className={styles.columnsCol}>
                      <FormField name="lastName" label={t('deposit.lastName')}>
                        <TextField
                          type="text"
                          placeholder={t('deposit.lastName')}
                        />
                      </FormField>
                    </Columns.Column>
                  </Columns>
                  {depositData.depositAmountUsd >= 1000 && (
                    <>
                      <Columns className={styles.columnsRow}>
                        <Columns.Column className={styles.columnsCol}>
                          <FormField
                            name="originatorGeographicAddress"
                            label={t('deposit.geographicAddress')}
                          >
                            <TextField
                              type="text"
                              placeholder={t('deposit.geographicAddress')}
                            />
                          </FormField>
                        </Columns.Column>
                        <Columns.Column className={styles.columnsCol}>
                          <Text
                            size={'14px'}
                            weight={'bold'}
                            margin={{ bottom: 'xsmall' }}
                          >
                            {t('deposit.countryOfResidence')}
                          </Text>
                          <ReactSelect
                            name="country"
                            options={renderCountryOptions()}
                            onChange={data => {
                              setFieldValue('country', data.value);
                            }}
                          />
                        </Columns.Column>
                      </Columns>
                      <Columns className={styles.columnsRow}>
                        <Columns.Column className={styles.columnsCol}>
                          <DatePicker
                            type="date"
                            name="originatorDoB"
                            label={
                              <Text
                                size={'14px'}
                                weight={'bold'}
                                margin={{ bottom: 'xsmall' }}
                              >
                                {t('deposit.dateOfBirth')}
                              </Text>
                            }
                            placeholder={t('deposit.dateOfBirth')}
                          />
                        </Columns.Column>
                        <Columns.Column className={styles.columnsCol}>
                          <Text
                            size={'14px'}
                            weight={'bold'}
                            margin={{ bottom: 'xsmall' }}
                          >
                            {t('deposit.placeOfBirth')}
                          </Text>
                          <ReactSelect
                            name="originatorCountryOfBirth"
                            options={renderCountryOptions()}
                            onChange={data => {
                              setFieldValue(
                                'originatorCountryOfBirth',
                                data.value,
                              );
                            }}
                          />
                        </Columns.Column>
                      </Columns>
                      <Columns className={styles.columnsRow}>
                        <Columns.Column className={styles.columnsCol}>
                          <FormField
                            name="originatorNationalIdentification"
                            label={t('deposit.nationalIdentificationNumber')}
                          >
                            <TextField
                              type="text"
                              placeholder={t(
                                'deposit.nationalIdentificationNumber',
                              )}
                            />
                          </FormField>
                        </Columns.Column>
                        <Columns.Column className={styles.columnsCol}>
                          <Text
                            size={'14px'}
                            weight={'bold'}
                            margin={{ bottom: 'xsmall' }}
                          >
                            {t('deposit.nationalIdentifierType')}
                          </Text>
                          <ReactSelect
                            name="originatorNationalIdentifierType"
                            options={renderNationalIdentifierType()}
                            onChange={data => {
                              setFieldValue(
                                'originatorNationalIdentifierType',
                                data.value,
                              );
                            }}
                          />
                        </Columns.Column>
                      </Columns>
                    </>
                  )}

                  <Box pad="none" margin={{ bottom: 'small' }}>
                    <CheckBox
                      name="iConfirmThatOriginatorInformationIsCorect"
                      label={
                        <Text size={'14px'} weight={'bold'}>
                          {t(
                            'deposit.iConfirmThatOriginatorInformationIsCorect',
                          )}
                        </Text>
                      }
                      onChange={data => {
                        setFieldValue(
                          'iConfirmThatOriginatorInformationIsCorect',
                          data,
                        );
                      }}
                    />
                  </Box>
                </Box>
                <Box
                  direction="row"
                  pad="small"
                  justify="end"
                  background="background-4"
                  gap="small"
                >
                  <Button onClick={closeModal}>{t('buttons.cancel')}</Button>
                  <Button
                    type="submit"
                    color="primary"
                    disabled={!values.iConfirmThatOriginatorInformationIsCorect}
                  >
                    {t('buttons.confirm')}
                  </Button>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </MessageModal>
    </>
  );
};

export const FiatDepositHistoryTable = ({
  data,
  t,
  currency,
  wallettype,
  getDepositHistoryFun,
}) => {
  const renderDelete = ({ value, original: { type, status } }) => {
    return (
      type === 'Bank' &&
      status === 'Pending' && (
        <Button
          color="primary"
          size="xsmall"
          onClick={() => mutate({ ID: value })}
        >
          {t('delete')}
        </Button>
      )
    );
  };
  const [mutate] = useMutation(ID => exchangeApi.removeFiatdepositrequest(ID), {
    onSuccess: result => {
      getDepositHistoryFun(currency, wallettype);
      triggerToast(result.message, 'success', 2500);
    },
  });

  return (
    <TableWrapper
      data={isArray(data) ? data : []}
      exportable={[
        'requestDate',
        'approvedDate',
        'requestAmount',
        'transactionID',
        'status',
        'rejectReason',
      ]}
      fileName="deposit-history"
      columns={[
        {
          Header: t('requestDate'),
          accessor: 'requestDate',
          Cell: ({ value }) =>
            moment
              .utc(value)
              .local()
              .format('L HH:mm'),
          style: { textAlign: 'center' },
          headerStyle: { textAlign: 'center' },
        },
        // {
        //   Header: t('approvedDate'),
        //   accessor: 'approvedDate',
        //   Cell: ({ value }) =>
        //     value &&
        //     moment
        //       .utc(value)
        //       .local()
        //       .format('L HH:mm'),
        //       style: {textAlign: 'center'},
        //       headerStyle: {textAlign: 'center'}
        // },
        {
          Header: t('requestAmount'),
          accessor: 'requestAmount',
          Cell: ({ value }) => formatNumberToPlaces(value),
          style: { textAlign: 'center' },
          headerStyle: { textAlign: 'center' },
        },
        {
          Header: t('transactionID'),
          accessor: 'transactionID',
          style: {
            textAlign: 'center',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          },
          headerStyle: { textAlign: 'center' },
        },
        {
          Header: t('status'),
          id: 'status',
          accessor: ({ status }) => t(`${status.toLowerCase()}`),
          maxWidth: 80,
          style: { textAlign: 'center' },
          headerStyle: { textAlign: 'center' },
        },
        {
          Header: t('type'),
          id: 'type',
          accessor: ({ type }) => t(`${type.toLowerCase()}`),
          style: { textAlign: 'center' },
          headerStyle: { textAlign: 'center' },
        },
        // {
        //   Header: t('rejectReason'),
        //   accessor: 'rejectReason',
        //   minWidth: 150,
        //   style: {textAlign: 'center'},
        //   headerStyle: {textAlign: 'center'}
        // },
        {
          Header: t('delete'),
          accessor: 'id',
          Cell: renderDelete,
          maxWidth: 125,
          style: { textAlign: 'center' },
          headerStyle: { textAlign: 'center' },
        },
      ]}
      // defaultSorted={[
      //   {
      //     id: 'depositConfirmDate',
      //     desc: true,
      //   },
      //   {
      //     id: 'depositReqDate',
      //     desc: true,
      //   },
      // ]}
      SubComponent={({ original }) => {
        const formattedRowData = Object.entries(original)
          .filter(([key]) => !key.startsWith('_'))
          .map(([key, val]) => {
            return (
              <TableSubComponentRow
                key={key}
                type={key}
                value={val}
                label={t(key)}
              />
            );
          });
        return <Box background="background-3">{formattedRowData}</Box>;
      }}
      className="-highlight"
      minRows={10}
      pageSize={10}
      showPagination={true}
      sortable={false}
    />
  );
};

class DepositHistoryTable extends React.PureComponent {
  renderDepositHistoryTable() {
    const {
      depositHistory,
      t: translate,
      currencySettings,
      match: {
        params: { currency },
      },
      notabene,
    } = this.props;

    const t = nestedTranslate(translate, 'tables.depositHistory');

    const dhData = depositHistory;

    const isFiat = _.has(dhData[0], 'type');

    const getDepositHistoryData = (curreny, walletType) => {
      this.props.getDepositHistory(curreny, walletType);
    };

    const Component = isFiat
      ? FiatDepositHistoryTable
      : CryptoDepositHistoryTable;

    return (
      <Component
        t={t}
        data={dhData}
        getDepositHistoryFun={getDepositHistoryData}
        currency={currency}
        wallettype={_.get(
          currencySettings,
          'walletType',
          currency === `ALLC` ? 'Crypto' : 'Fiat',
        )}
        notabene={notabene}
      />
    );
  }

  render() {
    const { depositHistory, t: translate } = this.props;
    const t = nestedTranslate(translate, 'tables.depositHistory');
    const dhData = depositHistory;

    return (
      <Fragment>
        {_.isEmpty(dhData) || !isArray(dhData) ? (
          <Box align="center" gap="small">
            <Transaction size="50px" />
            <Paragraph>{t('noDepositHistory')}</Paragraph>
          </Box>
        ) : (
          this.renderDepositHistoryTable()
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = ({
  portfolio,
  ui,
  exchangeSettings: {
    currencySettings,
    settings: { notabene, transactions },
  },
}) => ({
  currencySettings: currencySettings,
  depositHistory: portfolio.depositHistory,
  isModalOpen: ui.isModalOpen,
  notabene,
  transactions,
});

export default withRouter(
  withTranslation()(
    connect(mapStateToProps, { getDepositHistory })(DepositHistoryTable),
  ),
);
