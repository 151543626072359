import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { Box, Button, Modal } from 'components/Wrapped';

const MessageModal = ({ children, ModalButton, onClose, show, title, t }) => {
  return (
    <Modal
      heading={title}
      show={show}
      toggleModal={onClose}
      pad="none"
      width="large"
    >
      {children}
      {/* <Box pad="large">{children}</Box>
      <Box
        direction="row"
        pad="small"
        justify="end"
        background="background-4"
        gap="small"
      >
        {ModalButton}
      </Box> */}
    </Modal>
  );
};

MessageModal.propTypes = {
  children: PropTypes.node.isRequired,
  show: PropTypes.bool.isRequired,
  title: PropTypes.string,
};

MessageModal.defaultProps = {
  title: 'Confirm',
};

export default withTranslation()(MessageModal);
