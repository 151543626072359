// t is an instance of i18n.t
// nested is where in the translations the instance should be set
// key is the key that is wanted from the nested point
// initialize setting nestedTranslate(t, nested) to a variable and call that variable as a funciton

import { capitalize, isEmpty, last, split } from 'lodash';

// with the key that you want to use
export const nestedTranslate = (t, nested) => (key, rest) => {
  let defaultValue = last(split(key, '.'));
  let options = {};
  if (!isEmpty(rest)) {
    options = rest;
  }
  if (defaultValue === 'link') {
    const keySplit = split(key, '.');

    defaultValue =
      keySplit.length > 1
        ? capitalize(keySplit[keySplit.length - 2])
        : defaultValue;
    options.defaultValue = defaultValue;
  }

  return t(`${nested}.${key}`, options);
};

export const namespaceTranslate = (t, nameSpace) => (key, ...rest) =>
  t(`${nameSpace}:${key}`, ...rest);
