// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".InstaTrade_currencyCard__2mUQl {\n  border-top: 3px solid transparent;\n  height: 100%;\n  cursor: pointer;\n}\n.InstaTrade_currencyCard__2mUQl.InstaTrade_active__3PVcR {\n  border-top: 3px solid var(--primary);\n}", "",{"version":3,"sources":["webpack://src/pages/InstaTrade/InstaTrade.module.scss"],"names":[],"mappings":"AAGA;EACE,iCAAA;EACA,YAAA;EACA,eAAA;AAFF;AAIE;EACE,oCAAA;AAFJ","sourcesContent":["@import '~styles/variables';\n\n\n.currencyCard {\n  border-top: 3px solid transparent;\n  height: 100%;\n  cursor: pointer;\n\n  &.active {\n    border-top: 3px solid var(--primary);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"currencyCard": "InstaTrade_currencyCard__2mUQl",
	"active": "InstaTrade_active__3PVcR"
};
export default ___CSS_LOADER_EXPORT___;
