import React, { useState, useEffect, useRef } from 'react';
import styled, { keyframes } from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--background-1);
  color: var(--defaultTextColor);
  width: 100%;
  font-family: 'ProximaRegular', 'Open Sans', sans-serif;
`;

const ContentWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TextSection = styled.div`
  text-align: center;
  max-width: 800px;
  margin-bottom: 40px;
`;

const Title = styled.h2`
  font-size: 2.5rem;
  margin-bottom: 20px;
`;

const Description = styled.p`
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 30px;
`;

const CTAButton = styled.button`
  background-color: var(--primary);
  color: var(--defaultTextColor);
  border: none;
  padding: 12px 24px;
  font-size: 1.2rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #11e5a3;
  }
`;

const gradientAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const PhoneContainer = styled.div`
  width: 300px;
  height: 600px;
  border: 12px solid #2a2a3e;
  border-radius: 40px;
  overflow: hidden;
  position: relative;
  background: linear-gradient(45deg, #1a1a2e, #2a2a3e, #3a3a4e);
  background-size: 200% 200%;
  animation: ${gradientAnimation} 10s ease infinite;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.02);
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 150px;
    height: 20px;
    background-color: #2a2a3e;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }
`;

const ChatSection = styled.div`
  height: calc(100% - 50px); // Adjusted to account for the notch
  margin-top: 50px; // Push content down
  overflow-y: auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  scrollbar-width: thin;
  scrollbar-color: #3a3a4e transparent;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #3a3a4e;
    border-radius: 3px;
  }
`;

const ChatBubble = styled.div`
  max-width: 80%;
  padding: 12px 18px;
  border-radius: 20px;
  background-color: ${props =>
    props.sender === 'buyer' ? '#3498db' : '#95a5a6'};
  color: white;
  font-size: 14px;
  margin-bottom: 12px;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  transition-delay: ${props => props.delay}s;
  align-self: ${props =>
    props.sender === 'buyer' ? 'flex-end' : 'flex-start'};
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

  &.visible {
    opacity: 1;
    transform: translateY(0);
  }
`;

function OrderBookAnimation() {
  const [visibleBubbles, setVisibleBubbles] = useState(0);
  const [resetKey, setResetKey] = useState(0);
  const [isInView, setIsInView] = useState(false);
  const containerRef = useRef(null);

  const conversations = [
    { sender: 'buyer', message: "I'd like to sell ETH vs approx 888k myr?" },
    { sender: 'seller', message: 'moment please' },
    { sender: 'seller', message: '18,888' },
    { sender: 'buyer', message: 'can get a refresh?' },
    { sender: 'seller', message: 'I can do $18,838 each' },
    { sender: 'buyer', message: 'done please' },
    { sender: 'seller', message: 'ok noted' },
    { sender: 'seller', message: 'thanks for trade' },
  ];

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsInView(true);
          observer.disconnect();
        }
      },
      { threshold: 0.1 }, // Trigger when 10% of the component is visible
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    if (!isInView) return;

    const showTimer = setInterval(() => {
      setVisibleBubbles(prev => {
        if (prev < conversations.length) {
          return prev + 1;
        } else {
          clearInterval(showTimer);
          setTimeout(() => {
            setVisibleBubbles(0);
            setTimeout(() => {
              setResetKey(prev => prev + 1);
            }, 5000);
          }, 10000);
          return prev;
        }
      });
    }, 2000);

    return () => clearInterval(showTimer);
  }, [isInView, resetKey]);

  return (
    <Container ref={containerRef}>
      <ContentWrapper>
        <TextSection>
          <Title>Large crypto trades?</Title>
          <Description>
            Execute orders of more than $100,000 off the open exchange with our
            bespoke services. Get 24/7 access to deep liquidity, minimal
            slippage, and competitive execution and settlement services.
          </Description>
          <CTAButton>I'm a Whale Trader!</CTAButton>
        </TextSection>
        <PhoneContainer>
          <ChatSection>
            {conversations.map((conv, index) => (
              <ChatBubble
                key={`${resetKey}-${index}`}
                sender={conv.sender}
                delay={index * 0.5}
                className={index < visibleBubbles ? 'visible' : ''}
              >
                {conv.message}
              </ChatBubble>
            ))}
          </ChatSection>
        </PhoneContainer>
      </ContentWrapper>
    </Container>
  );
}

export default OrderBookAnimation;
